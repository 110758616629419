import React, { useEffect, useState } from 'react'
import { FieldPath, useFormContext } from 'react-hook-form'
import { IFormData, ISelectWithTextFieldAndCheckboxes } from '../../../interfaces'
import { TEXTFIELD_NAME_PROP } from '../../../constants/registrationForm'
import CheckboxComponent from './CheckboxComponent'
import SelectComponent from './SelectComponent'
import TextFieldComponent from './TextFieldComponent'
import { Typography, Box } from '@mui/material'

const SelectWithTextFieldAndCheckboxes = (props: ISelectWithTextFieldAndCheckboxes) => {
  const { watch, setValue } = useFormContext()
  const [textFieldLabel, setTextFieldLabel] = useState<Array<{ checkboxRef: string; value: string }>>([])
  const [checkboxValues, setCheckboxValues] = useState<string[]>([])
  const selectForm = watch('primaryDistributor')
  const checkBoxForm = watch('extraDistributors')

  useEffect(() => {
    setValue(props.checkboxName, [])
    setValue('distributorLocations', '')
    setValue('distributorOther', '')

    if (selectForm) {
      const checkbox = []
      for (const [index] of props.data.entries()) {
        if (props.data[index].value !== selectForm) {
          checkbox.push(props.data[index].name)
        }
      }
      setCheckboxValues(checkbox)
    }
  }, [selectForm])

  useEffect(() => {
    if (checkBoxForm.length) {
      let updatedTextFieldLabel = [...textFieldLabel]
      for (const object of props.data) {
        if (
          checkBoxForm.includes(object.name) &&
          object.textInputTitle &&
          !updatedTextFieldLabel.some((obj) => obj.checkboxRef === object.name)
        ) {
          updatedTextFieldLabel = [...updatedTextFieldLabel, { checkboxRef: object.name, value: object.textInputTitle }]
        } else if (
          !checkBoxForm.includes(object.name) &&
          updatedTextFieldLabel.some((obj) => obj.checkboxRef === object.name)
        ) {
          updatedTextFieldLabel = updatedTextFieldLabel.filter((obj) => obj.checkboxRef !== object.name)
        }
      }
      setTextFieldLabel(updatedTextFieldLabel)
    } else {
      setTextFieldLabel([])
    }
  }, [checkBoxForm])

  const renderTextField = (checkboxValue: string) => {
    const textField = textFieldLabel.find(({ checkboxRef }) => checkboxRef === checkboxValue)
    const name = TEXTFIELD_NAME_PROP.find((obj) => obj.textFieldRef === textField?.value)

    return (
      <>
        {checkBoxForm.includes(checkboxValue) && textField && name && (
          <Box>
            <TextFieldComponent
              fullWidth
              key={`${checkboxValue}-${textField.value}`}
              label={textField.value}
              name={name.value as FieldPath<IFormData>}
            />
          </Box>
        )}
      </>
    )
  }

  return (
    <>
      <SelectComponent name={props.name} data={props.data} label={props.label} title={props.title} />
      {checkboxValues.length ? (
        <Typography mt={1} mb={1}>
          Select all the additional ways customers can purchase your products/services:
        </Typography>
      ) : (
        ''
      )}
      {checkboxValues.map((value) => {
        return (
          <Box key={value}>
            <CheckboxComponent name={props.checkboxName} data={value} keyRef={selectForm} />
            {textFieldLabel && renderTextField(value)}
          </Box>
        )
      })}
    </>
  )
}

export default SelectWithTextFieldAndCheckboxes
