import React, { useState, useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Storage } from 'aws-amplify'
import RegistrationForm from '../../components/Registration/RegistrationForm'
import CustomerInfo from '../../components/Registration/CustomerInfo'
import { IFormData } from '../../interfaces'
import StepperButtons from '../../components/Registration/Inputs/StepperButtons'
import RegisterBenefits from '../../components/Registration/RegisterBenefits'
import CustomerShipmentComponent from '../../components/Registration/CustomerShipment/CustomerShipmentComponent'
import FinalDocumentsMain from '../../components/Registration/FinalDocuments/FinalDocumentsMain'
import RegistrationCompleted from './RegistrationCompleted'
import axios from 'axios'
import { BASE_URL, CURRENT_STAGE, EmailTemplates } from '../../constants'
import { postMemberMailchimp, sendEmail } from '../../services'
import Loading from '../../components/Loading'

import { addNotification } from '../../customHooks/notifications'
import { NotificationType } from '../../constants/notifications'

/**
 * Renders a registration form with multiple steps and uses the react-hook-form library for form management
 * @returns  {JSX.Element} - Registration component
 */
const Registration = () => {
  const totalSteps = 4
  const [activeStep, setActiveStep] = useState(0)
  const [noErrors, setNoErrors] = useState(false)
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [isSubmited, setIsSubmited] = useState(false)
  const methods = useForm<IFormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      country: '',
      media: '',
      socialMedia: '',
      businessDescription: '',
      businessDescriptionOther: '',
      companyName: '',
      jobRole: '',
      primaryBusinessWeb: '',
      typeOfCustomer: '',
      primaryBusiness: 'Yes',
      primaryPurchase: '',
      accountType: 'Professional',
      primaryDistributor: '',
      distributorLocations: '',
      distributorOther: '',
      extraDistributors: '',
      shippingAddress1: '',
      shippingAddress2: '',
      shipmentLocationType: 'Commercial',
      shipmentCountry: '',
      shippingCity: '',
      shippingCode: '',
      shippingRegion: '',
      ShippingBillingAddres: '',
      billingAddress1: '',
      billingAddress2: '',
      billingCountry: '',
      billingCity: '',
      billingCode: '',
      billingRegion: '',
      businessLicese: '',
      hasVATNumber: '',
      vatNumber: '',
      acceptConditions: '',
      newsLetter: 'false'
    },
    reValidateMode: 'onBlur',
    mode: 'onBlur'
  })
  const {
    handleSubmit,
    trigger,
    formState: { errors }
  } = methods

  /**
   * Handles the continue button click event and triggers form validation.
   * If there are no errors, sets the noErrors state to true.
   * Otherwise, sets the noErrors state to false.
   */
  const handleContinue = () => {
    setNoErrors(false)
    checkErrors()
  }

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  /**
   * Insert the form data into user attributes object for AWS Cognito.
   * @param {IFormData} data - Form data object
   * @returns {Object} - User attributes object for AWS Cognito
   */
  const userAttributes = (data: IFormData) => {
    const attributes = {
      email: data.email,
      password: data.password,
      'custom:firstName': data.firstName,
      'custom:lastName': data.lastName,
      'custom:phone': data.phone,
      'custom:companyName': data.companyName,
      'custom:accountType': data.accountType,
      'custom:country': data.country,
      'custom:media': data.media,
      'custom:socialMedia': data.socialMedia,
      'custom:businessDescription': data.businessDescription,
      'custom:jobRole': data.jobRole,
      'custom:primaryBusinessWeb': data.primaryBusinessWeb,
      'custom:typeOfCustomer': data.typeOfCustomer,
      'custom:primaryBusiness': data.primaryBusiness,
      'custom:primaryDistributor': data.primaryDistributor,
      'custom:distributorLocations': data.distributorLocations,
      'custom:distributorOther': data.distributorOther,
      'custom:extraDistributors': data.extraDistributors,
      'custom:shippingAddress1': data.shippingAddress1,
      'custom:shippingAddress2': data.shippingAddress2,
      'custom:shipmentLocationType': data.shipmentLocationType,
      'custom:shipmentCountry': data.shipmentCountry,
      'custom:shippingCity': data.shippingCity,
      'custom:shippingCode': data.shippingCode,
      'custom:shippingRegion': data.shippingRegion,
      'custom:billingAddress1': data.billingAddress1,
      'custom:billingAddress2': data.billingAddress2,
      'custom:billingCountry': data.billingCountry,
      'custom:billingCity': data.billingCity,
      'custom:billingCode': data.billingCode,
      'custom:billingRegion': data.billingRegion,
      'custom:vatNumber': data.vatNumber,
      'custom:newsLetter': data.newsLetter,
      'custom:acceptConditions': data.acceptConditions
    }
    return attributes
  }

  /**
   * Triggers form validation and checks if there are any errors.
   * If there are no trigger errors and no password errors, sets the noErrors state to true.
   * Otherwise, sets the noErrors state to false.
   * @returns {Promise<void>}
   */
  const checkErrors = async (): Promise<void> => {
    const noTriggerErrors = await trigger()
    let noPasswordError = true
    if (errors['confirmPassword']) {
      noPasswordError = false
    }

    if (noTriggerErrors && noPasswordError) {
      return setNoErrors(true)
    }
    setNoErrors(false)
  }

  /**
   * Deletes form empty fields
   * If a field has not value, will be removed from the data object.
   * @param {IFormData} data - Form data object
   * @returns {void}
   */
  const deleteEmptyFields = (data: IFormData) => {
    setLoading(true)
    let value
    for (const valueKey in data) {
      value = data[valueKey as keyof IFormData]
      if (!value || !value.length) {
        delete data[valueKey as keyof IFormData]
      }
    }
  }

  /**
   * Converts the data values that are arrays into string
   * If a field has an string array as a value, the array will be converted into string, join with a comma .
   * @param {IFormData} data - Form data object
   * @returns {void}
   */
  const converDataToString = (data: IFormData) => {
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        data[key as keyof IFormData] = value.join(', ')
      }
    })
  }

  /**
   * Generates the cognito userName by replacing spaces with underscores and converting it to lowercase.
   * @param {string} firstName - The original name.
   * @returns {string} The modified username.
   */
  const generateUserName = (firstName: string, lastName: string) => {
    return `${firstName.replace(/ /g, '_').toLowerCase()}_${lastName.replace(/ /g, '_').toLowerCase()}`
  }

  /**
   * Sign up a user and upload the ID documentation to AWS S3.
   * @param {IFormData} data - User form data.
   * @returns {Promise<void>} - Promise that resolves when the user is signed up and the file is uploaded successfully.
   * @throws {Error} - Throws an error if the file is missing on the form.
   */
  const signUpUser = async (data: IFormData) => {
    if (file) {
      let userName, userId
      try {
        userName = generateUserName(data.firstName, data.lastName)
        const response = await axios.post(`${BASE_URL}/stullereu/register`, {
          userName,
          ...userAttributes(data)
        })
        userId = JSON.parse(response.data.body)

        try {
          await Storage.put(`${userId}/${userId}`, file, {
            contentType: file.type
          })
          if (data.newsLetter === 'true') {
            await postMemberMailchimp(data.email)
          }
          await sendEmail(data.email, `${EmailTemplates.WELCOME}-${CURRENT_STAGE}`)
        } catch (error: any) {
          console.error(error)
          await axios.delete(`${BASE_URL}/stullereu/user`, { params: { Username: userName, userId: userId } })

          throw Error(error.message)
        }
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message ?? error.message
        console.error(error)
        addNotification(NotificationType.danger, errorMessage)
        setLoading(false)

        throw Error(errorMessage)
      }

      setIsSubmited(true)
    } else {
      setLoading(false)

      throw new Error('Error on regiser: Missing file on form')
    }
    setLoading(false)
  }

  /**
   * Handles the form submission event.
   * If there are no errors and the activeStep is the last step, creates a new AWS Cognito user account.
   * @param {IFormData} data - Form data object
   * @returns {Promise<void>}
   */
  const onSubmit: SubmitHandler<IFormData> = (data: IFormData) => {
    if (noErrors && activeStep === totalSteps - 1) {
      deleteEmptyFields(data)
      converDataToString(data)
      signUpUser(data)
    }
  }

  /**
   * Renders the current step in the registration form
   * @param step - Current step number in the registration form
   * @returns The JSX element for the current step
   */
  const renderStepper = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return <RegistrationForm handleContinue={handleContinue} goToTop={goToTop} />
      case 1:
        return <CustomerInfo />
      case 2:
        return <CustomerShipmentComponent />
      case 3:
        return <FinalDocumentsMain setFile={setFile} file={file} />
      default:
        return <></>
    }
  }

  /**
   * this effect updates the active step when after execute handleOnClick there are no errors
   * and the next step is within the total steps limit
   */
  useEffect(() => {
    const nextStep = activeStep + 1
    if (noErrors && nextStep < totalSteps) {
      setActiveStep(nextStep)
    }
  }, [noErrors])

  return (
    <>
      {loading ? (
        <Loading />
      ) : isSubmited ? (
        <RegistrationCompleted />
      ) : (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {renderStepper(activeStep)}
            {activeStep > 0 && (
              <StepperButtons
                goToTop={goToTop}
                totalSteps={totalSteps}
                handleContinue={handleContinue}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                file={file}
              />
            )}
          </form>
          <RegisterBenefits />
        </FormProvider>
      )}
    </>
  )
}

export default Registration
