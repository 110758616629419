import { Radio, FormControlLabel, FormHelperText, Grid, RadioGroup } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { IRadioButtonsGroup } from '../../../interfaces'

/**
 * @interface IRadioButtonsGroup
 * @returns Radio group to render into a form
 */
const RadioButtonsGroup = ({ required, name, radioValues, onChangeProp, grid }: IRadioButtonsGroup) => {
  const {
    control,
    trigger,

    formState: { errors }
  } = useFormContext()

  const handleOnChange = (e: React.SyntheticEvent<Element, Event>, onChange: (...event: any[]) => void) => {
    onChange(e.currentTarget)
    trigger(name)

    //In case an external control is needed will execute the onChangeProp function
    if (onChangeProp) {
      onChangeProp(e)
    }
  }

  return (
    <Controller
      rules={{ required: required }}
      control={control}
      name={name}
      render={({ field }) => (
        <RadioGroup {...field} name='radio-buttons-group'>
          {grid ? (
            <Grid container>
              {radioValues.map((value) => {
                return (
                  <Grid key={value} item xs={12} md={6}>
                    <FormControlLabel
                      onChange={(e) => handleOnChange(e, field.onChange)}
                      value={value}
                      control={<Radio />}
                      label={value}
                    />
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            radioValues.map((value) => {
              return <FormControlLabel key={value} value={value} control={<Radio />} label={value} />
            })
          )}
          <FormHelperText error>{errors[name]?.type && 'Required'}</FormHelperText>
        </RadioGroup>
      )}
    />
  )
}
export default RadioButtonsGroup
