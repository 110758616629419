import { Box, Container, Grid, Typography } from '@mui/material'
import RadioButtonsGroup from '../Inputs/RadioButtonsGroup'
import DropZoneComponent from './DropZone/DropZoneComponent'
import { IDropZoneFile } from '../../../interfaces'
import { useFormContext } from 'react-hook-form'
import TextFieldComponent from '../Inputs/TextFieldComponent'
import CheckboxField from '../Inputs/CheckBoxField'

const FinalDocumentsMain = ({ file, setFile }: IDropZoneFile) => {
  const { watch, setValue } = useFormContext()

  const handleOnChange = (e: React.SyntheticEvent<Element, Event>) => {
    const value = (e.currentTarget as HTMLInputElement).value
    if (value === 'No') {
      setValue('vatNumber', '')
    }
  }

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Box>
        <Typography variant='h1'>Let's wrap up some final documents and info</Typography>
        <Box component={Container} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Container>
                <Typography variant='h3' sx={{ pb: 2 }}>
                  Select Your Passport or Driver's License
                </Typography>
                <Typography variant='h4' sx={{ pb: 1 }}>
                  Upload your Passport or Driver's License
                </Typography>
                <DropZoneComponent file={file} setFile={setFile} />
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container>
                <Typography variant='h3' sx={{ pb: 2 }}>
                  Selected File
                </Typography>
                {file ? (
                  <Typography variant='h4' sx={{ pb: 1 }}>
                    {file.name}
                  </Typography>
                ) : (
                  <Typography variant='h3' sx={{ pb: 1 }}>
                    You have not yet selected a file.
                  </Typography>
                )}
              </Container>
            </Grid>
          </Grid>
        </Box>
        <Box padding={2}>
          <Typography> Do you have a Government Issued Business License? </Typography>
          <RadioButtonsGroup radioValues={['Yes', 'No']} name='businessLicese' required grid />
        </Box>
        <Box padding={2}>
          <Typography> Do you have the EU Company VAT Number? </Typography>
          <RadioButtonsGroup
            radioValues={['Yes', 'No']}
            name='hasVATNumber'
            onChangeProp={handleOnChange}
            required
            grid
          />
          {watch('hasVATNumber') === 'Yes' && (
            <Box>
              <Typography paddingBottom={1}>Insert your VAT number</Typography>
              <TextFieldComponent label='Your VAT Number' required name='vatNumber' />
            </Box>
          )}
        </Box>
        <Box padding={2}>
          <CheckboxField label='Would you like to receive our newsletter?' name={'newsLetter'} />
        </Box>
        <Box padding={2}>
          <CheckboxField
            required
            link
            label='I agree to the payment terms and conditions of a Stuller account. '
            name={'acceptConditions'}
          />
        </Box>
      </Box>
    </Container>
  )
}

export default FinalDocumentsMain
