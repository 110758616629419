import { MenuItem, Select, FormHelperText, InputLabel, FormControl, Typography, SelectChangeEvent } from '@mui/material'
import { Box } from '@mui/system'
import { Country } from 'country-list'
import { Controller, useFormContext } from 'react-hook-form'
import { IRegistrationSelect } from '../../../interfaces'

/**
 * @param label string
 * @param name FieldPath<IRegistrationForm>;
 * @param required boolean
 * @param data object[]
 * @param title string
 * @param setPrimaryValue Dispatch<SetStateAction<string>>
 * @returns Renders a select with the given options on the data prop
 */
const SelectComponent = ({ data, name, label, required, title, setPrimaryValue }: IRegistrationSelect) => {
  const {
    control,
    trigger,
    formState: { errors }
  } = useFormContext()

  const handleOnChange = (e: SelectChangeEvent<any>, onChange: (...event: any[]) => void) => {
    onChange(e)
    setPrimaryValue?.(e.target.value)
    trigger(name)
  }

  return (
    <Box>
      <Typography>{title}</Typography>
      <FormControl fullWidth size='small' style={{ marginTop: 12 }}>
        <Controller
          rules={{ required: !!required }}
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <>
              <InputLabel id='select-label'>{label}</InputLabel>
              <Select
                error={!!errors[name]?.type}
                displayEmpty
                id='registration-select'
                value={value}
                label={label}
                onChange={(e) => handleOnChange(e, onChange)}
              >
                {data.map((obj: { value: string; name: string } | Country) => {
                  const value = Object.values(obj)[0]
                  return (
                    <MenuItem value={value} key={value}>
                      {obj.name}
                    </MenuItem>
                  )
                })}
              </Select>
              <FormHelperText error>{errors[name] && 'Required'}</FormHelperText>
            </>
          )}
        />
      </FormControl>
    </Box>
  )
}
export default SelectComponent
