import { Stack, Container, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'

export default function RegisterBlock() {
  return (
    <Stack
      justifyContent={'center'}
      style={{
        background: 'url(https://meteor.stullercloud.com/das/115424495)',
        backgroundPosition: 'center center',
        backgroundSize: 'cover'
      }}
    >
      <Container>
        <Stack
          alignItems={'center'}
          maxWidth={600}
          margin={'auto'}
          sx={{
            p: { xs: 2, md: 4, lg: 8 },
            py: { xs: 4, md: 8, lg: 8 }
          }}
        >
          <Typography variant='h2' align='center' color={'primary'} pb={4}>
            It's your first time on Stuller Europe?
          </Typography>
          <Typography variant='h3' align='center' color={'primary'} pb={2}>
            Sign Up and get the all benefits.
          </Typography>
          <Typography color={'primary'} align='center' pb={2}>
            Register now and enjoy the most complete and quality store of jewelry in Europe, with close, and totally
            qualified professionals.
          </Typography>
          <Button variant='contained' color='secondary' component={Link} to='/registration'>
            Create an Account
          </Button>
        </Stack>
      </Container>
    </Stack>
  )
}
