import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { IUserState } from '../interfaces'

export default function ProtectedRoute(props: { children: React.ReactNode }): React.ReactElement | null {
  const userState = useSelector((state: { userAccount: IUserState }) => state.userAccount)
  const { isAuthenticated } = userState

  if (!isAuthenticated) {
    return <Navigate to='/login' />
  }

  return <>{props.children}</>
}
