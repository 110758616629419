import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { IRegistrationSelectDinamic } from '../../../interfaces'
import CheckboxComponent from './CheckboxComponent'
import RadioButtonsGroup from './RadioButtonsGroup'
import SelectComponent from './SelectComponent'
import TextFieldComponent from './TextFieldComponent'
import { Box } from '@mui/material'

enum SubInputType {
  Radio = 'radio',
  Checkbox = 'checkbox',
  Text = 'text'
}

const SelectDinamicInput = (props: IRegistrationSelectDinamic) => {
  const [primaryValue, setPrimaryValue] = useState('')
  const [secondInputValues, setSecondInputValues] = useState<string[]>([])
  const { resetField } = useFormContext()

  const getFirstInputValues = () => {
    const values = []
    for (const { selectValue } of props.data) {
      values.push({ value: selectValue.value, name: selectValue.name })
    }
    return values
  }

  const getSecondInputValues = (selectedPrimaryValue: string) => {
    for (const { selectValue, subValues } of props.data) {
      if (selectValue.value === selectedPrimaryValue) {
        return subValues
      }
    }
    return
  }

  const renderSecondInput = (subInputType: string) => {
    if (secondInputValues.length) {
      switch (subInputType) {
        case SubInputType.Radio:
          return (
            <Box>
              <RadioButtonsGroup
                radioValues={secondInputValues}
                required={props.secondaryRequired}
                name={props.nameSubComponent}
                grid={props.grid}
              />
            </Box>
          )
        case SubInputType.Checkbox:
          return secondInputValues.map((value) => (
            <Box>
              <CheckboxComponent required={props.secondaryRequired} data={value} name={props.nameSubComponent} />
            </Box>
          ))
        case SubInputType.Text:
          const capitalizeValues = secondInputValues.map((value) => value.charAt(0).toUpperCase() + value.slice(1))
          const label = capitalizeValues.join('/')

          return (
            <Box mt={1}>
              <TextFieldComponent
                required={props.secondaryRequired}
                name={props.nameSubComponent}
                label={label}
                placeholder={label}
              />
            </Box>
          )
      }
    }
    return <></>
  }

  useEffect(() => {
    resetField(props.nameSubComponent)
    const values = getSecondInputValues(primaryValue)
    if (values) {
      return setSecondInputValues(values)
    }
    return setSecondInputValues([])
  }, [primaryValue])

  return (
    <>
      <SelectComponent
        setPrimaryValue={setPrimaryValue}
        data={getFirstInputValues()}
        label={props.label}
        required={props.primaryRequired}
        title={props.title}
        name={props.name}
      />
      {primaryValue && renderSecondInput(props.subInputType)}
    </>
  )
}
export default SelectDinamicInput
