export const BUSINESS_DESCRIPTION = [
  {
    selectValue: { value: 'antique/state business', name: 'Antique/State Business' }
  },
  {
    selectValue: { value: 'apparel/boutique business', name: 'Apparel/Boutique Business' }
  },
  {
    selectValue: { value: 'appraisal business', name: 'Appraisal Business' }
  },
  {
    selectValue: { value: 'award/gifts/commemorative items', name: 'Award/Gifts/Commemorative Items' }
  },
  {
    selectValue: { value: 'department store', name: 'Department Store' }
  },
  {
    selectValue: { value: 'designer', name: 'Designer' }
  },
  {
    selectValue: { value: 'diamond/gemstone dealer', name: 'Diamond/Gemstone Dealer' }
  },
  {
    selectValue: { value: 'government agency/public institution', name: 'Government Agency/Public Institution' }
  },
  {
    selectValue: { value: 'i do not own a business', name: 'I do not own a business' }
  },
  {
    selectValue: { value: 'insurance provider', name: 'Insurance Provider' }
  },
  {
    selectValue: { value: 'jewelry reseller', name: 'Jewelry Reseller' }
  },
  {
    selectValue: { value: 'manifacturer', name: 'Manufacturer' }
  },
  {
    selectValue: { value: 'media/entertainment', name: 'Media/Entertainment' }
  },
  {
    selectValue: { value: 'wellness', name: 'Medical/Health/Beauty' }
  },
  {
    selectValue: { value: 'pawn business', name: 'Pawn business' }
  },
  {
    selectValue: { value: 'refiner', name: 'Refiner' }
  },
  {
    selectValue: { value: 'religious business', name: 'Religious Business' }
  },
  {
    selectValue: { value: 'school', name: 'School' }
  },
  {
    selectValue: { value: 'service business repair/restore', name: 'Service Business (Repair/Maintenance/Restore)' }
  },
  {
    selectValue: { value: 'student', name: 'Student' }
  },
  {
    selectValue: { value: 'wholesaler/distributor', name: 'Wholesaler/Distributor' }
  },
  {
    selectValue: { value: 'other', name: 'Other' },
    subValues: ['other']
  }
]
export const JOB_ROLE = [
  { value: 'accountant', name: 'Accountant' },
  { value: 'appraiser', name: 'Appraiser' },
  { value: 'bench jeweler', name: 'Bench Jeweler' },
  { value: 'bookkeeper', name: 'Bookkeeper' },
  { value: 'buyer', name: 'Buyer' },
  { value: 'cad designer', name: 'CAD Designer' },
  { value: 'ceo - chief executive officer', name: 'CEO - Chief Executive Officer' },
  { value: 'cfo - chief financial officer', name: 'CFO - Chief Financial Officer' },
  { value: 'cmo - chief marketing officer', name: 'CMO - Chief Marketing Officer' },
  { value: 'co-owner', name: 'Co-Owner' },
  { value: 'controller', name: 'Controller' },
  { value: 'coo - chief operating officer', name: 'COO - Chief Operating Officer' },
  { value: 'coordinator', name: 'Coordinator' },
  { value: 'cs designer', name: 'CS Designer' },
  { value: 'cto - chief technology officer', name: 'CTO - Chief Technology Officer' },
  { value: 'curator', name: 'Curator' },
  { value: 'dealer', name: 'Dealer' },
  { value: 'designer', name: 'Designer' },
  { value: 'director', name: 'Director' },
  { value: 'foreman', name: 'Foreman' },
  { value: 'gemcutter', name: 'Gemcutter' },
  { value: 'gemologist', name: 'Gemologist' },
  { value: 'goldsmith', name: 'Goldsmith' },
  { value: 'instructor', name: 'Instructor' },
  { value: 'jeweler', name: 'Jeweler' },
  { value: 'manager', name: 'Manager' },
  { value: 'merchandiser', name: 'Merchandiser' },
  { value: 'metal smith', name: 'Metal Smith' },
  { value: 'owner', name: 'Owner' },
  { value: 'partner', name: 'Partner' },
  { value: 'president', name: 'President' },
  { value: 'public relations', name: 'Public Relations' },
  { value: 'sales person', name: 'Sales Person' },
  { value: 'secretary', name: 'Secretary' },
  { value: 'silversmith', name: 'Silversmith' },
  { value: 'student', name: 'Student' },
  { value: 'trainer', name: 'Trainer' },
  { value: 'treasurer', name: 'Treasurer' },
  { value: 'vice president', name: 'Vice President' },
  { value: 'watch maker', name: 'Watch Maker' }
]

export const ACCOUNT_TYPES = ['Professional', 'Jewelry Student']
export const PRIMARY_BUSINESS = ['Yes', 'No']
export const SHIPMENT_LOCATION_TYPE = ['Commercial', 'Residential']
export const MARKETING_SOURCE = [
  {
    selectValue: { value: 'catalog/brochure', name: 'Catalog/Brochure' }
  },
  {
    selectValue: {
      value: 'electronic advertisement',
      name: 'Electronic Advertisement'
    },
    subValues: [
      'AGTA',
      'Canadian Jeweller',
      'Ganoskin',
      'IDEX Online',
      'Instore Online',
      'JCK Online',
      'Jewellery Business',
      'Jewelry Artist',
      'MJSA Online',
      'National Jeweler',
      'National Pawnbrokers Association',
      'Polygon',
      'Rapaport Diamond Report',
      'Other'
    ]
  },
  {
    selectValue: {
      value: 'industry tradeshow or event',
      name: 'Industry tradeshow or Event'
    },
    subValues: [
      'Atlanta Jewelry Show',
      'Expo Prestige',
      'Fast Fix',
      'GJX',
      'JCK',
      'MJSA',
      'RJO',
      'The Smart Show',
      'Other'
    ]
  },
  { selectValue: { value: 'internet search', name: 'Internet Search' } },
  { selectValue: { value: 'metalsmith society', name: 'Metalsmith Society' } },
  { selectValue: { value: 'modern goldsmith', name: 'Modern Goldsmith' } },
  { selectValue: { value: 'prior account holder', name: 'Prior Account Holder' } },
  { selectValue: { value: 'prior industry employee', name: 'Prior Industry Employee' } },
  { selectValue: { value: 'referral', name: 'Referral' } },
  { selectValue: { value: 'school', name: 'School' } },
  {
    selectValue: {
      value: 'social media',
      name: 'Social Media'
    },
    subValues: ['Facebook', 'Instagram', 'Pinterest', 'Twitter', 'YouTube', 'Other']
  },
  {
    selectValue: {
      value: 'trade magazine advertisement',
      name: 'Trade Magazine Advertisement'
    },
    subValues: [
      'Canadian Jeweller',
      'IDEX Magazine',
      'INSTORE Magazine',
      'JCK Magazine',
      'Jewellery Business',
      'Lapidary Journal/Jewelry Artist',
      'Mid America Jewelry News',
      'MJSA Journal',
      "National Pawnbroker's Association",
      'Southern Jewelry News',
      'The Retail Jeweler',
      'Other'
    ]
  },
  { selectValue: { value: 'web interest form', name: 'Web Interest Form' } },
  { selectValue: { value: 'other', name: 'other' } }
]
export const CUSTOMER_TARGET = [
  { value: 'both', name: 'Both' },
  { value: 'b2b', name: 'Businesses (B2B)' },
  { value: 'b2c', name: 'Consumers (B2C)' },
  { value: 'neither', name: 'Neither' }
]
export const PURCHASE_OPTIONS = [
  { value: '3rd party distributor', name: '3rd Party Distributor (Includes Stockists)' },
  { value: 'art fairs/traveling shows', name: 'Art Fairs or Traveling Shows' },
  { value: 'art gallery or museum', name: 'Art Gallery or Museum' },
  { value: 'business website', name: 'Business Website' },
  { value: 'online marketplace', name: 'Online Marketplace' },
  { value: 'private office/studio', name: 'Private Office/Studio (Includes Residential or Commercial)' },
  { value: 'storefront', name: 'Storefront (Includes Kiosks)', textInputTitle: 'Number of locations?' },
  { value: 'warehouse or showroom', name: 'Warehouse or Showroom' },
  {
    value: 'other',
    name: 'Other',
    textInputTitle: 'List the ways customers purchase from you:'
  }
]

export const TEXTFIELD_NAME_PROP = [
  { value: 'distributorLocations', textFieldRef: 'Number of locations?' },
  { value: 'distributorOther', textFieldRef: 'List the ways customers purchase from you:' }
]
