import { Stack, Container, Grid, Typography } from '@mui/material'
import { GroupsOutlined, HandshakeOutlined, FlightOutlined } from '@mui/icons-material'

export default function RegisterBenefits() {
  return (
    <Container>
      <Grid container columnSpacing={5} pt={{ xs: 5, md: 7 }} pb={{ xs: 7, md: 10 }}>
        <Grid item xs={12} md={4} mb={{ xs: 4, md: 0 }}>
          <Stack>
            <Stack alignItems={'center'} mb={1}>
              <GroupsOutlined fontSize='large' color='secondary' />
            </Stack>
            <Typography variant='h4' align='center' color='primary' mb={1}>
              Join a family of professionals
            </Typography>
            <Typography align='center' color='primary'>
              We protect our customers by only allowing qualified businesses and students to be account holders.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} mb={{ xs: 4, md: 0 }}>
          <Stack>
            <Stack alignItems={'center'} mb={1}>
              <HandshakeOutlined fontSize='large' color='secondary' />
            </Stack>

            <Typography variant='h4' align='center' color='primary' mb={1}>
              Integrity and innovation since 1970
            </Typography>
            <Typography variant='body1' align='center' color='primary'>
              We've been a trusted partner to jewelry retailers, manufacturers, students, and online sellers for
              decades.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} mb={0}>
          <Stack>
            <Stack alignItems={'center'} mb={1}>
              <FlightOutlined fontSize='large' color='secondary' />
            </Stack>
            <Typography variant='h4' align='center' color='primary' mb={1}>
              Broad selection and fast delivery
            </Typography>
            <Typography variant='body1' align='center' color='primary'>
              Rely on Stuller for just in time delivery of everything you need for your jewelry operations.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}
