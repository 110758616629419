import { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import { UserType } from '@aws-sdk/client-cognito-identity-provider'

interface ISearchBar {
  users: UserType[]
  setUsers: React.Dispatch<React.SetStateAction<UserType[]>>
}

const SearchBar = (props: ISearchBar) => {
  const [searched, setSearched] = useState('')

  const requestSearch = () => {
    let filteredUsers = props.users

    if (searched.length) {
      filteredUsers = props.users.filter((user) => {
        const usernameIncluded = user?.Username?.toLowerCase().includes(searched.toLowerCase())
        const attributeIncluded = user?.Attributes?.find((attr) => {
          return attr.Name === 'email' && attr.Value?.toLowerCase().includes(searched.toLowerCase())
        })

        return usernameIncluded || attributeIncluded
      })
    }

    props.setUsers(filteredUsers)
  }

  useEffect(() => {
    requestSearch()
  }, [searched, props.users])

  return (
    <TextField
      sx={{ paddingBottom: '16px' }}
      variant='standard'
      style={{ width: '50%' }}
      placeholder='Search'
      value={searched}
      onChange={(event) => setSearched(event.target.value)}
    />
  )
}

export default SearchBar
