import { Box, Checkbox, Container, FormControlLabel, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import CustomerShipmentForm from './CustomerShipmentForm'

const CustomerShipmentComponent = () => {
  const [isBillingAddres, setIsBillingAddres] = useState(true)
  const { setValue } = useFormContext()

  useEffect(() => {
    if (isBillingAddres) {
      setValue('billingAddress1', '')
      setValue('billingAddress2', '')
      setValue('billingCountry', '')
      setValue('billingCity', '')
      setValue('billingRegion', '')
      setValue('billingCode', '')
    }
  }, [isBillingAddres])

  return (
    <Container>
      <Box margin={'3rem auto 1rem'} maxWidth={600}>
        <Typography variant='h3' mb={3}>
          Where will you receive shipments?
        </Typography>
        <CustomerShipmentForm />
        <Box mt={1}>
          <FormControlLabel
            control={<Checkbox onChange={(e) => setIsBillingAddres(e.target.checked)} checked={isBillingAddres} />}
            label={'This is also my billing address'}
          />
        </Box>
        {!isBillingAddres && (
          <>
            <Typography variant='h3' mt={2} mb={3}>
              What is your billing address?
            </Typography>
            <CustomerShipmentForm billingAddress />
          </>
        )}
      </Box>
    </Container>
  )
}

export default CustomerShipmentComponent
