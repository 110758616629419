import { Button, InputBase } from '@mui/material'
import { createTheme, styled } from '@mui/material/styles'

const fontSizebody2 = 'calc(.75rem + 2*(100vw - 20rem)/980)'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#555'
    },
    secondary: {
      main: '#bd9c53'
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 50,
          fontWeight: 200
        },
        h2: {
          fontSize: 40,
          fontWeight: 200
        },
        h3: {
          fontSize: 24,
          fontWeight: 400
        },
        h4: {
          fontSize: 18,
          fontWeight: 400,
          lineHeight: 1.25,
          letterSpacing: 0
        },
        body1: {
          fontWeight: 300
        },
        body2: {
          fontSize: fontSizebody2
        },
        caption: {
          fontSize: 13,
          fontWeight: 300,
          lineHeight: 1
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#eee'
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          marginTop: 0
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: 50,
          textTransform: 'none',
          fontWeight: 400,
          letterSpacing: 0
        },
        sizeSmall: {
          fontSize: 14,
          lineHeight: 'initial',
          padding: '4px 15px'
        },
        sizeMedium: {
          fontSize: 16,
          lineHeight: 'initial',
          padding: '8px 25px'
        },
        sizeLarge: {
          fontSize: 'calc(16px + (2 * (100vw - 20rem)) / 980)',
          lineHeight: 'initial',
          padding: '15px 40px'
        },
        containedSecondary: {
          color: '#fff',
          '&:hover': {
            background: '#d8b86c'
          }
        },
        text: {
          '&:hover': {
            background: 'none',
            textDecoration: 'none'
          }
        },
        outlinedPrimary: {
          color: '#555',
          borderColor: '#555',
          '&:hover': {
            color: '#eee',
            background: '#555'
          }
        }
      }
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: '›'
      },
      styleOverrides: {
        root: {
          fontSize: '13px',
          padding: '5px 0'
        },
        li: {
          fontSize: '13px',
          fontWeight: '300'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#ddd',
          margin: '2rem auto'
        }
      }
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true
      }
    }
  }
})

export const ButtonWhite = styled(Button)(() => ({
  border: '1px solid #eee',
  color: '#eee',
  '&:hover': {
    border: '1px solid white',
    background: '#eee',
    color: '#555'
  }
}))

export const CustomSelect = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    borderRadius: 50,
    position: 'relative',
    border: '1px solid #d6d6d6',
    fontSize: 15,
    padding: '7px 26px 7px 20px',
    color: '#555',
    transition: 'all .1s ease',

    '&:hover': {
      borderRadius: 50,
      borderColor: '#555'
    },

    '&:focus': {
      borderRadius: 50,
      borderColor: '#3b3b3b'
    }
  }
}))
