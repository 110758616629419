import { Stack, Container, Grid, Typography, Button } from '@mui/material'
import RadioButtonsGroup from './Inputs/RadioButtonsGroup'
import TextFieldComponent from './Inputs/TextFieldComponent'
import { ACCOUNT_TYPES } from '../../constants/registrationForm/index'
import { IRegistrationForm } from '../../interfaces'
import ReCAPTCHA from 'react-google-recaptcha'
import '../../containers/Registration/Registration.css'
import { useState } from 'react'
import { RECAPTCHA_KEY } from '../../constants'

/**
 * @interface IRegistrationForm
 * @returns Render the first Step of a registration form
 */
const RegistrationForm = ({ handleContinue, goToTop }: IRegistrationForm) => {
  const [isCaptchaEmpty, setIsCaptchaEmpty] = useState(true)

  const handleCaptchaOnChange = () => {
    setIsCaptchaEmpty(false)
  }

  return (
    <>
      <Stack className='signup-option-step'>
        <Container>
          <Stack pt={{ xs: 6, md: 5 }} pb={{ xs: 6, md: 5 }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant='h2' mb={1.5} style={{ color: '#eee' }}>
                  Open a Stuller account and open yourself to success
                </Typography>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={2}
                  sx={{
                    '& .MuiButtonBase-root': { borderRadius: '4px' },
                    '& label.MuiFormLabel-root': {
                      color: '#ccc',
                      fontWeight: 300
                    },
                    '& .MuiFormControlLabel-root': {
                      color: '#ccc'
                    },
                    '& label.Mui-focused': {
                      color: '#bd9c53'
                    },
                    '& .MuiRadio-root': {
                      color: '#888'
                    },
                    '& .MuiRadio-root.Mui-checked': {
                      color: '#eee'
                    },
                    '& .MuiOutlinedInput-root': {
                      color: '#eee',
                      fontWeight: 300,
                      '& fieldset': {
                        borderColor: '#ccc'
                      },
                      '&:hover fieldset': {
                        borderColor: '#eee'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#be9f56'
                      },
                      '&.Mui-error fieldset': {
                        borderColor: '#d32f2f'
                      }
                    }
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <TextFieldComponent fullWidth={true} name='firstName' label={'First Name*'} required={true} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldComponent fullWidth={true} name='lastName' label={'Last Name*'} required={true} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldComponent
                      fullWidth={true}
                      name='email'
                      label={'Email*'}
                      required={true}
                      pattern={'email'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldComponent
                      fullWidth={true}
                      type={'tel'}
                      name='phone'
                      label={'Phone*'}
                      required={true}
                      placeholder={'(+99) 999-999'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldComponent
                      type='password'
                      fullWidth={true}
                      name='password'
                      label={'Password'}
                      required={true}
                      pattern={'password'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldComponent
                      type='password'
                      fullWidth={true}
                      name='confirmPassword'
                      label={'Confirm Password'}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextFieldComponent fullWidth={true} name='companyName' label={'Company Name'} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RadioButtonsGroup required={true} name='accountType' radioValues={ACCOUNT_TYPES} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={handleCaptchaOnChange} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      fullWidth
                      size='large'
                      onClick={() => {
                        handleContinue()
                        goToTop()
                      }}
                      type='submit'
                      variant='contained'
                      color='secondary'
                      disabled={isCaptchaEmpty}
                    >
                      Apply Now
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={12} paddingTop={0}>
                    <Typography variant='caption' color={'#ccc'}>
                      By completing this form you are agreeing to our privacy policy. You may also receive emails
                      regarding your Stuller account.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Stack>
    </>
  )
}

export default RegistrationForm
