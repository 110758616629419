import { Box, CircularProgress } from '@mui/material'

const Loading = () => {
  return (
    <Box padding={1} sx={{ height: '70vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress color='secondary' />
    </Box>
  )
}
export default Loading
