import { Typography, Paper } from '@mui/material'
import './legal.css'
import { Link } from 'react-router-dom'

export default function LegalTopic(props: any) {
  return (
    <Paper elevation={1} className='legal-topic-content'>
      <Link to={props.link}>
        {props.icon}
        <Typography variant='h4' color='primary'>
          {props.title}
        </Typography>
      </Link>
    </Paper>
  )
}
