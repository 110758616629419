import { Box, Container, Typography } from '@mui/material'
import TextFieldComponent from '../Inputs/TextFieldComponent'
import RadioButtonsGroup from '../Inputs/RadioButtonsGroup'
import SelectComponent from '../Inputs/SelectComponent'
import { getData } from 'country-list'
import { SHIPMENT_LOCATION_TYPE } from '../../../constants/registrationForm'
import { IFormData } from '../../../interfaces'
import { FieldPath } from 'react-hook-form'

const CustomerShipmentForm = ({ billingAddress = false }) => {
  const formNames: Record<string, FieldPath<IFormData>> = !billingAddress
    ? {
        address1: 'shippingAddress1',
        address2: 'shippingAddress2',
        location: 'shipmentLocationType',
        country: 'shipmentCountry',
        city: 'shippingCity',
        region: 'shippingRegion',
        postalCode: 'shippingCode'
      }
    : {
        address1: 'billingAddress1',
        address2: 'billingAddress2',
        country: 'billingCountry',
        city: 'billingCity',
        region: 'billingRegion',
        postalCode: 'billingCode'
      }

  return (
    <>
      <Box mt={2}>
        <TextFieldComponent label='Address 1*' required name={formNames.address1} fullWidth />
      </Box>
      <Box mt={2}>
        <TextFieldComponent label='Address 2 optional for ste, apt, bldg' name={formNames.address2} fullWidth />
      </Box>
      {!billingAddress && (
        <Box mt={2} mb={2}>
          <Typography>What type of location is this?*</Typography>
          <Container>
            <RadioButtonsGroup radioValues={SHIPMENT_LOCATION_TYPE} name={formNames.location} grid />
          </Container>
        </Box>
      )}
      <Box mt={2}>
        <SelectComponent
          data={getData()}
          required={true}
          title='Country*'
          name={formNames.country}
          label={'Select Country...'}
        />
      </Box>
      <Box mt={2}>
        <TextFieldComponent label='City*' required name={formNames.city} fullWidth />
      </Box>
      <Box mt={2}>
        <TextFieldComponent label='Province/Region*' required name={formNames.region} fullWidth />
      </Box>
      <Box mt={2}>
        <TextFieldComponent label='Postal Code*' required name={formNames.postalCode} fullWidth />
      </Box>
    </>
  )
}

export default CustomerShipmentForm
