import { CURRENT_STAGE } from './constants'

const awsconfig =
  CURRENT_STAGE === 'prod'
    ? {
        Auth: {
          region: 'us-east-1',
          userPoolId: 'us-east-1_ZWUafmJKx',
          userPoolWebClientId: '1b8b2ploaa1qitqacik2nkcmvk',
          identityPoolId: 'us-east-1:073b10b4-bbd6-4de0-ade3-006ccac22a3c'
        },
        Storage: {
          AWSS3: {
            bucket: 'stullereubackendstack-stullereuroperegistrationbu-8wj9l0pjkh4b',
            region: 'us-east-1',
            identityPoolId: 'us-east-1:073b10b4-bbd6-4de0-ade3-006ccac22a3c'
          }
        }
      }
    : {
        Auth: {
          region: 'us-east-1',
          userPoolId: 'us-east-1_R5Jj7sMAC',
          userPoolWebClientId: '7g79ffkhematvcdo5nppoddip0',
          identityPoolId: 'us-east-1:bf08952c-c2c6-411f-abf8-06f218098edb'
        },
        Storage: {
          AWSS3: {
            bucket: 'stullereubackendstack-de-stullereuroperegistratio-16ndh7o3hxjfj',
            region: 'us-east-1',
            identityPoolId: 'us-east-1:bf08952c-c2c6-411f-abf8-06f218098edb'
          }
        }
      }

export default awsconfig
