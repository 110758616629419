import { Typography, Container, Stack, Paper, Grid, Button } from '@mui/material'
import { Phone, WhatsApp, MailOutline, Schedule } from '@mui/icons-material'

export default function Contact() {
  return (
    <Container>
      <Stack>
        <Typography variant='h2' color='primary' align='center' pb={4} pt={4}>
          Contact Us
        </Typography>
        <Paper
          sx={{
            p: { sx: 0, md: 4 },
            bgcolor: { sx: 'transparent', md: '#f8f8f8' },
            mb: 4,
            maxWidth: 800,
            m: 'auto'
          }}
          elevation={0}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <Schedule fontSize='small' />
            <Typography>Monday - Friday 08:30 a.m. - 6:00 p.m Central European</Typography>
          </Stack>
          <Typography variant='body2' mb={2} ml={3.5}>
            English and Spanish speaking associates
          </Typography>
          <Stack spacing={1}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Phone fontSize='small' />
              <Typography>Office Phone:</Typography>
              <a href='tel:+34937547774'>+34 937 547 774</a>
            </Stack>
            <Stack direction='row' spacing={1} alignItems='center'>
              <WhatsApp fontSize='small' />
              <Typography>WhatsApp:</Typography>
              <a href='https://wa.me/34722687266' target='_blank' rel='noopener noreferrer'>
                +34 722 687 266
              </a>
            </Stack>
            <Stack direction='row' spacing={1} alignItems='center'>
              <MailOutline fontSize='small' />
              <Typography>Email:</Typography>
              <a href='mailto:europe@stuller.com'>europe@stuller.com</a>
            </Stack>
          </Stack>
          <Typography fontWeight='400' mt={3}>
            STULLER EUROPE SLU
          </Typography>
          <Typography>Moll de Ponent s/n</Typography>
          <Typography>Mataró, BARCELONA</Typography>
          <Typography>08304 - Spain</Typography>
        </Paper>
      </Stack>
      <Stack>
        <Typography variant='h2' color='primary' align='center' mt={8}>
          About Us
        </Typography>
        <Grid container spacing={4} display='flex' alignItems='center' mb={8}>
          <Grid item xs={12} md={6}>
            <Typography mb={2}>
              In 1970, Matt Stuller founded Stuller Inc. from modest beginnings and an unmovable focus on the customer.
              Today, Stuller is leading the industry as the largest manufacturer and distributor of jewelry and
              jewelry-related tools, supplies, and equipment in the United States.
            </Typography>
            <Typography mb={2}>
              With more than 1,200 employees worldwide—from artisans to engineers and bench jewelers to
              technologists—Stuller is more committed than ever to delivering an unparalleled customer experience with
              every order. It is a constant process of improving, listening to customers, and working with the best
              people to deliver on our promise of quality, service, and satisfaction.
            </Typography>
            <Button
              size='small'
              variant='outlined'
              href='https://www.stuller.com/about/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn more
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className='videowrapper'>
              <iframe
                width='560'
                height='315'
                src='https://www.youtube.com/embed/PqG-7hOpE-I'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              ></iframe>
            </div>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  )
}
