import { Stack, Container, Grid, Typography } from '@mui/material'
import { DiamondOutlined, InventoryOutlined, WorkspacePremiumOutlined } from '@mui/icons-material'

export default function BenefitsBlock() {
  return (
    <Stack
      sx={{
        p: { xs: 2, md: 4, lg: 8 },
        py: { xs: 4, md: 8, lg: 8 }
      }}
      bgcolor={'#f8f8f8'}
    >
      <Container>
        <Typography variant='h2' align='center' color='primary'>
          Stuller Europe Benefits
        </Typography>
        <Grid container columnSpacing={8} mt={{ xs: 3, md: 6 }}>
          <Grid item xs={12} md={4} mb={{ xs: 4, md: 0 }}>
            <Stack>
              <Stack alignItems={'center'} mb={1}>
                <DiamondOutlined color='secondary' />
              </Stack>
              <Typography variant='h4' align='center' color='primary' mb={1}>
                The Biggest Jewelry Catalogue for the professionals
              </Typography>
              <Typography align='center' color='primary'>
                Utilize the largest jewelry manufacturer and supplier of the United States with local European shipping
                fees without the need to face Customs. Stuller Service Center Europe imports goods twice a week from US,
                ready to ship to you afterwards within 24h.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} mb={{ xs: 4, md: 0 }}>
            <Stack>
              <Stack alignItems={'center'} mb={1}>
                <InventoryOutlined color='secondary' />
              </Stack>
              <Typography variant='h4' align='center' color='primary' mb={1}>
                Earth First Commitment
              </Typography>
              <Typography variant='body1' align='center' color='primary'>
                At Stuller, we are committed to ethical sourcing and environmental sustainability in all aspects of our
                operations. This includes Stuller's Responsible Sourcing Protocol and using 100% recycled materials in
                our manufacturing processes.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} mb={0}>
            <Stack>
              <Stack alignItems={'center'} mb={1}>
                <WorkspacePremiumOutlined color='secondary' />
              </Stack>
              <Typography variant='h4' align='center' color='primary' mb={1}>
                How Stuller Helps You
              </Typography>
              <Typography variant='body1' align='center' color='primary'>
                We provide a comprehensive range of products and services for jewelers: bridal jewelry, wedding bands,
                fine jewelry, mountings, diamonds, gemstones, findings, metals, tools, packaging, and digital solutions.
                Use our Services such as CAD/CAM, custom imprinting, shop set up, and more...
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Stack>
  )
}
