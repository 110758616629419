import { useEffect, useState } from 'react'

const useMediaQuery = () => {
  const [state, setState] = useState({
    windowWidth: window.innerWidth,
    isDesiredWidth: false
  })

  const resizeHandler = () => {
    const currentWindowWidth = window.innerWidth
    const isDesiredWidth = currentWindowWidth < 767
    setState({ windowWidth: currentWindowWidth, isDesiredWidth })
  }

  useEffect(() => {
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  return state.isDesiredWidth
}

export default useMediaQuery
