import { NOTIFICATION_TYPE, Store } from 'react-notifications-component'

export const addNotification = (type: NOTIFICATION_TYPE | undefined, message: string) => {
  const capitalizedType = `${type?.charAt(0).toUpperCase()}${type?.slice(1)}`

  Store.addNotification({
    title: type === 'danger' ? 'Error' : capitalizedType,
    message: message,
    type: type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  })
}
