import { Box, Button, Paper, Typography, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import styled from '@emotion/styled'

const CookiesBG = styled(Box)({
  width: '100%',
  height: '100%',
  background: '#0002',
  position: 'fixed',
  zIndex: 999,
  top: 0,
  padding: '1rem',
  display: 'flex',
  alignItems: 'flex-end'
})
const CookiesBox = styled(Box)({
  maxWidth: 720,
  margin: 'auto'
})

const CookiesPaper = styled(Paper)({
  width: '100%',
  backgroundColor: '#eee',
  bottom: 0,
  padding: '2rem'
})

const CookiesBanner = () => {
  const [cookies, setCookie] = useCookies(['acceptCookies'])

  const acceptCookies = () => {
    setCookie('acceptCookies', true, { path: '/', expires: new Date(Date.now() + 2592000000) })
  }

  if (cookies['acceptCookies'] === 'true') {
    return null
  }

  return (
    <CookiesBG>
      <CookiesPaper elevation={16}>
        <CookiesBox>
          <Typography align='left'>
            We use cookies to personalize our service and improve your experience on the website. We also use this
            information for analytics.
          </Typography>
          <Stack
            sx={{ flexDirection: { sx: 'column', sm: 'row' } }}
            mt={2}
            gap={1}
            display='flex'
            justifyContent='center'
            alignItems={'center'}
          >
            <Button variant='contained' onClick={acceptCookies}>
              Accept Cookies
            </Button>
            <Button component={Link} to='/legal/cookies'>
              Cookies Policy
            </Button>
          </Stack>
        </CookiesBox>
      </CookiesPaper>
    </CookiesBG>
  )
}

export default CookiesBanner
