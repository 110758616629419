import React, { useEffect } from 'react'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import NavBar from './components//Navbar/navBar'
import NavBarMobile from './components//Navbar/navBarMobile'
import AppRoutes from './routes'
import Footer from './components/Footer/footer'
import { theme } from './styles'
import { ThemeProvider } from '@mui/material/styles'
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
import useMediaQuery from './customHooks/screenSize'
import { CssBaseline } from '@mui/material'
import CookiesBanner from './containers/CookiesBanner'
import { useDispatch } from 'react-redux'
import { signInUser } from './services'
import 'react-notifications-component/dist/theme.css'
import { ReactNotifications } from 'react-notifications-component'

Amplify.configure(awsconfig)

export default function App() {
  const isMobile = useMediaQuery()
  const dispatch = useDispatch()

  useEffect(() => {
    signInUser(dispatch)
  }, [])
  return (
    <BrowserRouter>
      <ReactNotifications />
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <div className='App'>
            {isMobile ? <NavBarMobile /> : <NavBar />}
            <AppRoutes />
            <Footer />
            <CookiesBanner />
          </div>
        </CssBaseline>
      </ThemeProvider>
    </BrowserRouter>
  )
}
