import {
  CognitoIdentityProviderClient,
  AdminEnableUserCommand,
  ListUsersCommand,
  AdminConfirmSignUpCommand,
  AdminDisableUserCommand,
  AdminUpdateUserAttributesCommand,
  UserType,
  AdminDeleteUserCommand,
  ListUsersCommandOutput
} from '@aws-sdk/client-cognito-identity-provider'
import awsconfig from '../aws-exports'
import { Auth, Storage } from 'aws-amplify'
import axios from 'axios'
import { BASE_URL, EMAIL_IDENTITY, LIST_ID } from '../constants'
import { AnyAction, Dispatch } from 'redux'
import { logOut, loginAccount } from '../redux/slices/userAccountSlice'

const USER_POOL_ID = awsconfig.Auth.userPoolId
const REGION = awsconfig.Auth.region
const ADMITED_GROUPS = ['AdminGroup', 'AdminGroup-dev']

const getCognitoIdentityServiceProvider = async () => {
  try {
    const credentials = await Auth.currentUserCredentials()

    return new CognitoIdentityProviderClient({
      credentials,
      region: REGION
    })
  } catch (error: any) {
    console.error(error)
    throw new Error(`Error in Cognito Client: ${error.message}`)
  }
}

export const getUserPoolUsers = async (): Promise<ListUsersCommandOutput['Users']> => {
  try {
    const cognitoService = await getCognitoIdentityServiceProvider()
    if (!cognitoService) {
      throw new Error("Error: Couldn't connect with cognitoService")
    }
    let users: ListUsersCommandOutput['Users'] = []
    let paginationToken = undefined
    do {
      const response: ListUsersCommandOutput = await cognitoService.send(
        new ListUsersCommand({ UserPoolId: USER_POOL_ID, PaginationToken: paginationToken })
      )
      users = users.concat(response.Users || [])
      paginationToken = response.PaginationToken
    } while (paginationToken)

    return users
  } catch (error: any) {
    console.error(error)
    throw new Error(`Error obtaining user: ${error.message}`)
  }
}

export const confirmUserSignUp = async (Username: any) => {
  const cognitoService = await getCognitoIdentityServiceProvider()

  try {
    if (!cognitoService) {
      throw new Error("Error: Couldn't connect with cognitoService")
    }
    await cognitoService.send(
      new AdminConfirmSignUpCommand({
        UserPoolId: USER_POOL_ID,
        Username
      })
    )
  } catch (error: any) {
    console.error(error)
    throw new Error(`Error in activateUser: ${error.message}`)
  }
}

export const updateUser = async (user: any) => {
  const attributes = user.Attributes.filter((attribute: any) => attribute.Name !== 'sub')
  const cognitoService = await getCognitoIdentityServiceProvider()
  try {
    if (!cognitoService) {
      throw new Error("Error: Couldn't connect with cognitoService")
    }
    await cognitoService.send(
      new AdminUpdateUserAttributesCommand({
        UserPoolId: USER_POOL_ID,
        Username: user.Username,
        UserAttributes: attributes
      })
    )
  } catch (error: any) {
    console.error(error)
    throw new Error(`Error in updateUser: ${error.message}`)
  }
}

export const enableUser = async (Username: any) => {
  const cognitoService = await getCognitoIdentityServiceProvider()

  try {
    if (!cognitoService) {
      throw new Error("Error: Couldn't connect with cognitoService")
    }
    await cognitoService.send(
      new AdminEnableUserCommand({
        UserPoolId: USER_POOL_ID,
        Username
      })
    )
  } catch (error: any) {
    console.error(error)
    throw new Error(`Error in enableUser: ${error.message}`)
  }
}

export const disableUser = async (Username: any) => {
  const cognitoService = await getCognitoIdentityServiceProvider()

  try {
    if (!cognitoService) {
      throw new Error("Error: Couldn't connect with cognitoService")
    }
    await cognitoService.send(
      new AdminDisableUserCommand({
        UserPoolId: USER_POOL_ID,
        Username
      })
    )
  } catch (error: any) {
    console.error(error)
    throw new Error(`Error in disableUser: ${error.message}`)
  }
}

export const deleteUser = async (Username: any) => {
  const cognitoService = await getCognitoIdentityServiceProvider()

  try {
    if (!cognitoService) {
      throw new Error("Error: Couldn't connect with cognitoService")
    }
    await cognitoService.send(
      new AdminDeleteUserCommand({
        UserPoolId: USER_POOL_ID,
        Username
      })
    )
  } catch (error: any) {
    console.error(error)
    throw new Error(`Error on disableUser: ${error.message}`)
  }
}

export const getDocumentFromBucket = async (user: UserType) => {
  const id = user.Attributes?.find((attr) => attr.Name === 'sub')?.Value
  let fileURL = ''

  if (id) {
    try {
      const fileList = await Storage.list('')
      const fileFound = fileList.results.some((object) => object.key === `${id}/${id}`)

      if (fileFound) {
        fileURL = await Storage.get(`${id}/${id}`)
      }
    } catch (error: any) {
      console.error(error)
      throw new Error(`Error in disableUser: ${error.message}`)
    }
  }

  return fileURL
}

export const sendEmail = async (destination: string, templateName: string, firstName?: string) => {
  try {
    await axios.post(`${BASE_URL}/stullereu/sendEmail`, {
      destination,
      fromEmailAddress: EMAIL_IDENTITY,
      templateName,
      firstName: firstName
    })
  } catch (error: any) {
    console.error(error)
    throw new Error(`Error in sending Email: ${error.message}`)
  }
}

export const postMemberMailchimp = async (email: string) => {
  try {
    await axios.post(`${BASE_URL}/stullereu/member`, {
      email,
      listId: LIST_ID
    })
  } catch (error: any) {
    console.error(error)
    if (error.response.data.message !== 'Member Exists') {
      throw new Error(`Error in subscribing to newsletter: ${error.response.data.message ?? error.message}`)
    }
  }
}

export const deleteMailchimpMember = async (email: string) => {
  try {
    await axios.delete(`${BASE_URL}/stullereu/member`, {
      params: {
        email,
        listId: LIST_ID
      }
    })
  } catch (error: any) {
    console.error(error)
    throw new Error(`Error in unsubscribing from newsletter: ${error.response.data.message ?? error.message}`)
  }
}

export const handleLogOut = async (dispatch: Dispatch<AnyAction>) => {
  await Auth.signOut()
  dispatch(logOut())
}

export const signInUser = async (dispatch: Dispatch<AnyAction>) => {
  let userName
  let admin = false
  try {
    const { signInUserSession, username } = await Auth.currentAuthenticatedUser({ bypassCache: true })

    admin = signInUserSession.accessToken.payload['cognito:groups']?.some((group: string) =>
      ADMITED_GROUPS.includes(group)
    )
      ? true
      : false
    userName = username

    dispatch(
      loginAccount({
        userName: userName,
        isAuthenticated: true,
        isAdmin: admin,
        baseURL: 'https://stullereurope.jewelershowcase.com'
      })
    )

    return { isAdmin: admin, userName: userName }
  } catch (error) {
    handleLogOut(dispatch)
    return { isAdmin: admin, userName: undefined }
  }
}
