import * as React from 'react'
import { AppBar, Toolbar, Slide } from '@mui/material'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { Stack } from '@mui/system'
import { Link } from 'react-router-dom'
import Hamburger from './navBarHamburger'
import Profile from './navBarProfile'

interface Props {
  children?: React.ReactElement
}

function HideOnScroll(props: Props) {
  const { children } = props
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children!}
    </Slide>
  )
}

export default function HideAppBar(props: Props) {
  return (
    <HideOnScroll {...props}>
      <AppBar style={{ background: '#eee' }} elevation={0}>
        <Toolbar>
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
            <Link to='/' style={{ display: 'flex' }}>
              <img
                style={{ height: 40 }}
                alt='Stuller Europe logo'
                src={require('../../img/stuller-europe-horizontal-logo.svg').default}
              />
            </Link>
            <div>
              <Profile />
              <Hamburger />
            </div>
          </Stack>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  )
}
