import { Stack } from '@mui/material'
import ShowCasePrice from '../components/ShowCasePrices'
import { useLocation } from 'react-router-dom'

export default function Iframe() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const title = queryParams.get('title') ?? ''

  /**
   * Extracts the path from the URL.
   * Takes only the path from url query params, excluding title.
   *
   * @returns {string} The path extracted from the URL.
   */
  const getPath = () => {
    return location.search.split('?url=')[1].split('&title=')[0].trim()
  }

  return (
    <>
      <Stack>
        <div style={{ width: '100%', height: '600px', minHeight: '100vh' }}>
          <ShowCasePrice title={title} path={getPath()} />
        </div>
      </Stack>
    </>
  )
}
