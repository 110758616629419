import React, { PropsWithChildren } from 'react'
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuList } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import './navBar.css'

interface INavBarMenuList {
  title: string
  width?: string
  borderRadius?: string
}

export default function NavBarMenuList(props: PropsWithChildren<INavBarMenuList>) {
  const [open, setOpen] = React.useState(false)

  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      <Button
        className='btn-navbar'
        disableTouchRipple
        endIcon={<ExpandMore style={{ marginLeft: -4, transform: open ? 'rotate(180deg)' : 'none' }} />}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onMouseDown={handleToggle}
      >
        {props.title}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 999, maxWidth: '100%', width: props.width, overflowX: 'hidden' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper elevation={1} style={{ background: '#333', overflowX: 'auto', borderRadius: props.borderRadius }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                  {React.Children.map(props.children, (child) =>
                    React.cloneElement(child as React.ReactElement, {
                      handleClose: handleClose
                    })
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
