import React from 'react'
import { Link } from 'react-router-dom'
import { MenuItem } from '@mui/material'
import './navBar.css'

export interface INavBarHamburgerItem {
  url: string
  title: string
  handleClose?(event: React.MouseEvent): void
}

export default function NavBarHamburgerItem(props: INavBarHamburgerItem) {
  return (
    <MenuItem
      style={{ minHeight: 32, fontSize: 14, padding: '0 1rem' }}
      disableTouchRipple
      onClick={(event) => {
        props.handleClose!(event)
      }}
      component={Link}
      to={props.url}
    >
      {props.title}
    </MenuItem>
  )
}
