import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { IDialogComponent } from '../../../interfaces'

const DialogComponent = (props: IDialogComponent) => {
  return (
    <Dialog open={props.isOpen}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <Typography>{props.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (props.selectedUser) {
              props.action(props.selectedUser)
            }
          }}
        >
          Yes
        </Button>
        <Button onClick={() => props.action({})}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
export default DialogComponent
