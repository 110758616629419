import { Stack } from '@mui/material'
import ShowCasePrice from '../../components/ShowCasePrices'

export default function Jewelry() {
  return (
    <Stack>
      <div style={{ width: '100%', height: '600px', minHeight: '100vh' }}>
        <ShowCasePrice title='Jewelry' path={'browse/jewelry'} />
      </div>
    </Stack>
  )
}
