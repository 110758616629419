import { Stack, Grid, IconButton } from '@mui/material'
import { Facebook, Twitter, YouTube, Pinterest, Instagram } from '@mui/icons-material'

export default function PreFooter() {
  return (
    <Stack direction='row' alignItems='center' justifyContent='center' p={{ xs: '1rem 0', md: '1rem 0' }}>
      <Grid item xs={12} md={4}>
        <Stack direction={{ xs: 'row', md: 'row' }} alignItems='center' justifyContent={{ xs: 'center', md: 'center' }}>
          <IconButton href='https://www.facebook.com/Stullerinc/' target='_blank' rel='noopener noreferrer'>
            <Facebook style={{ color: '#0c89ef' }} />
          </IconButton>
          <IconButton href='https://www.instagram.com/Stullerinc/' target='_blank' rel='noopener noreferrer'>
            <Instagram style={{ color: '#d93175' }} />
          </IconButton>
          <IconButton href='https://www.youtube.com/StullerInc/' target='_blank' rel='noopener noreferrer'>
            <YouTube style={{ color: '#ff0000' }} />
          </IconButton>
          <IconButton href='https://www.twitter.com/StullerInc/' target='_blank' rel='noopener noreferrer'>
            <Twitter style={{ color: '#1d9bf0' }} />
          </IconButton>
          <IconButton href='https://www.pinterest.com/Stullerinc/' target='_blank' rel='noopener noreferrer'>
            <Pinterest style={{ color: '#e40226' }} />
          </IconButton>
        </Stack>
      </Grid>
    </Stack>
  )
}
