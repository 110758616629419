import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { UserType } from '@aws-sdk/client-cognito-identity-provider'
import { getUserPoolUsers } from '../../services'
import { useSelector } from 'react-redux'
import { IUserState } from '../../interfaces'
import Loading from '../../components/Loading'
import AdminTable from '../../components/Admin/AdminTable'
import { addNotification } from '../../customHooks/notifications'
import { NotificationType } from '../../constants/notifications'

const AdminMain = () => {
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState<UserType[] | undefined>([])
  const { isAdmin } = useSelector((state: { userAccount: IUserState }) => state.userAccount)
  const navigate = useNavigate()

  const getUsers = async () => {
    setLoading(true)
    let users
    if (!isAdmin) {
      navigate('/')
      return
    }
    try {
      users = await getUserPoolUsers()
    } catch (error: any) {
      console.error(error)
      setLoading(false)
      addNotification(NotificationType.danger, error.message)

      throw new Error(error.message)
    }
    setLoading(false)
    return users
  }

  const fetchUsers = async () => {
    const userPoolUsers = await getUsers()
    setUsers(userPoolUsers)
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return <>{loading ? <Loading /> : isAdmin && users && <AdminTable users={users} setUsers={setUsers} />}</>
}

export default AdminMain
