import { Stack, Container, Typography, Divider } from '@mui/material'
import './legal.css'

export default function Privacy() {
  return (
    <Stack mt={6} mb={12}>
      <Container className='legal-wrapper'>
        <Typography variant='h2' align='center' color='primary'>
          Privacy
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Information we collect
        </Typography>
        <Typography color='primary' gutterBottom>
          At STULLER EUROPE SLU (“Stuller Europe”) your privacy matters to us and we are committed to respect and
          protect your privacy and your personal information. Stuller Europe, will not rent, sell, or share personally
          identifiable information about you with other people or companies except for services you've requested, when
          we have your permission, or when we believe it is necessary to share information in order to investigate,
          prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats
          to the physical safety of any person, violations of these terms and conditions, or as otherwise required by
          law or a policy or notice contained or associated with a specific product or service. Personally identifiable
          information includes such information as your full name, phone number, email address, or similar, when you
          register for an account. To update your account's personally identifiable information, you can email us at{' '}
          <a href='mailto:europe@stuller.com'>europe@stuller.com</a>. We also send promotional emails and service
          related emails related to your account. If you no longer wish to receive these emails, you may opt out of
          them.
        </Typography>
        <Typography color='primary' gutterBottom>
          As you browse Stuller.eu, we use cookies, beacons, tags and scripts to collect non - personal information
          including standard internet log information and details of your behavioral patterns upon visiting our website
          pages. We do this to provide you with a better experience, identify preferences, diagnose technical problems,
          analyze trends, and to improve our website.This information is for internal use only - allowing us to deliver
          the best experience to our visitors.Information from the cookies is used to gauge page popularity, analyze
          traffic patterns on our site and guide development of other improvements to our site.
        </Typography>
        <Typography color='primary' gutterBottom>
          Stuller Europe uses multiple analytic tools that may record mouse clicks, mouse movements, scrolling activity,
          pages visited, country, device used, operating system, browser used, etc. None of these analytic tools collect
          and use your personally identifiable information.
        </Typography>
        <Typography color='primary' gutterBottom>
          For more information, contact us at <a href='tel:+34.937.547.774'>+34 937 547 774</a> (Phone),{' '}
          <a href='tel:+34.722.687.266'>+34 722 687 266</a> (WhatsApp) or{' '}
          <a href='mailto:europe@stuller.com'>europe@stuller.com</a>.
        </Typography>
        <Divider />
        <Typography color='primary' variant='h3' gutterBottom>
          Storage
        </Typography>
        <Typography color='primary' gutterBottom>
          Any information collected by Stuller Europe is stored or processed in accordance with the applicable data
          protection regulations.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          How We Protect Your Information
        </Typography>
        <Typography color='primary' gutterBottom>
          Stuller Europe is concerned with protecting your privacy and data, but we cannot ensure or warrant the
          security of any information you transmit to Stuller Europe or guarantee that your information may not be
          accessed, disclosed, altered or destroyed by breach of any of our industry standard physical, technical or
          managerial safeguards. When you enter sensitive information (such as log in credentials) on our registration
          or order forms, we encrypt that information using secure socket layer technology (SSL). No method of
          transmission over the Internet or method of electronic storage, is 100% secure, however. Therefore, we cannot
          guarantee its absolute security. If you have any questions about security on our Site, you can contact us at{' '}
          <a href='mailto:europe@stuller.com'>europe@stuller.com</a>. We use an outside help platform, and a credit card
          processing company. These companies do not retain, share, store or use personally identifiable information for
          any other purposes.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Compromise of Personal Information
        </Typography>
        <Typography color='primary' gutterBottom>
          In the event that personal information is compromised as a breach of security, Stuller Europe will promptly
          notify you in compliance with applicable law.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Data Access
        </Typography>
        <Typography color='primary' gutterBottom>
          You can review, correct, update or delete inaccuracies to the information by logging into your account to
          update your password and billing information. Alternately, you can contact us directly at{' '}
          <a href='tel:+34.937.547.774'>+34 937 547 774</a> (Phone), <a href='tel:+34.722.687.266'>+34 722 687 266</a>{' '}
          (WhatsApp) or <a href='mailto:europe@stuller.com'>europe@stuller.com</a>. We will acknowledge your request and
          handle it promptly and as required by law. We will retain your information for as long as your account is
          active or as needed to provide you services. We will retain and use your information as necessary to comply
          with our legal obligations, resolve disputes and enforce our agreements.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Social Media Widgets
        </Typography>
        <Typography color='primary' gutterBottom>
          Our Site includes social media features, such as the Facebook Like button and other widgets that run on our
          Site. These features may collect your IP address and which page you are visiting on our Site, and may set a
          cookie to enable the feature to function properly. Social media features and widgets are either hosted by a
          third party or hosted directly on our Site. Your interactions with these features are governed by the privacy
          policy of the organization providing it.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Changes to our Privacy Policy
        </Typography>
        <Typography color='primary' gutterBottom>
          All changes to this Privacy Policy are effective when they are posted on this page. When we change the policy
          in a material manner, we will let you know via email and/or a prominent notice on our site, prior to the
          change becoming effective and update the 'effective date' at the top of this page.
        </Typography>
        <Typography color='primary' gutterBottom>
          If you have any questions about this Privacy Policy, the practices of this Site, or your dealings with this
          website, please call us at <a href='tel:+34.937.547.774'>+34 937 547 774</a> (Phone),{' '}
          <a href='tel:+34.722.687.266'>+34 722 687 266</a> (WhatsApp) or{' '}
          <a href='mailto:europe@stuller.com'>europe@stuller.com</a>. You can also write to us at:
        </Typography>
        <ul className='legal-ul'>
          <li>STULLER EUROPE SLU</li>
          <li>Moll de Ponent s/n</li>
          <li>Mataró (08304), Barcelona, Spain</li>
        </ul>
        <Typography color='primary' gutterBottom>
          Any disputes arising out of or related to usage of Stuller.eu shall be governed by, and construed and enforced
          in accordance with, the Eurpean laws.
        </Typography>
      </Container>
    </Stack>
  )
}
