import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { UserType, AttributeType } from '@aws-sdk/client-cognito-identity-provider'
import { Box, IconButton, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { getDocumentFromBucket, getUserPoolUsers } from '../../services'
import { ArrowBack, AttachFile } from '@mui/icons-material'
import { Stack } from '@mui/system'
import Loading from '../Loading'
import { addNotification } from '../../customHooks/notifications'
import { NotificationMessages, NotificationType } from '../../constants/notifications'

const AdminListUserAttributes = () => {
  const [userData, setUserData] = useState<UserType | undefined>({})
  const [attributes, setAttributes] = useState<AttributeType[] | undefined>([])
  const [fileFromBucket, setFileFromBucket] = useState('')
  const [loading, setLoading] = useState(true)

  const { userName } = useParams()
  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!fileFromBucket.length) {
      event.preventDefault()
      addNotification(NotificationType.danger, NotificationMessages.ERROR_DOCUMENT_NO_EXIST)
    }
  }

  const transformAttributes = (attributes: AttributeType[]) => {
    return attributes.map((attribute) => {
      if (attribute.Name) {
        const transformedName = attribute.Name.replace('custom:', '')
        return { ...attribute, Name: transformedName }
      }
      return attribute
    })
  }

  const setUsersAndAttributes = async () => {
    const usersFromUserPool = await getUserPoolUsers()
    if (usersFromUserPool) {
      const user = usersFromUserPool.find((user) => user.Username === userName)
      if (user && user.Attributes) {
        setFileFromBucket(await getDocumentFromBucket(user))
        setUserData(user)
        setAttributes(transformAttributes(user.Attributes))
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    setUsersAndAttributes()
  }, [])
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {userData && (
            <Box padding={2} overflow='auto' mt={4} mb={8}>
              <Table size='small' style={{ maxWidth: 1080, margin: 'auto' }}>
                <TableBody>
                  <>
                    <TableRow key={userName}>
                      <TableCell>
                        <Stack direction='row' mb={2} spacing={2} alignItems='center'>
                          <Tooltip title='Go Back'>
                            <IconButton size='small' onClick={() => navigate(-1)}>
                              <ArrowBack />
                            </IconButton>
                          </Tooltip>
                          <Typography fontWeight={400}>{userName}</Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </>
                  {attributes && (
                    <>
                      {attributes.map((attribute) => (
                        <TableRow
                          key={attribute.Name}
                          sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: '#f8f8f8'
                            }
                          }}
                        >
                          <TableCell>{attribute.Name}</TableCell>
                          <TableCell>{attribute.Value}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell>document</TableCell>
                        <TableCell>
                          <IconButton
                            component={Link}
                            to={fileFromBucket.length ? fileFromBucket : '#'}
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={(e) => handleClick(e)}
                          >
                            <AttachFile fontSize='small' />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default AdminListUserAttributes
