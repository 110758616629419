import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Box, AppBar, Toolbar, IconButton } from '@mui/material'
import './navBar.css'
import NavBarMenuItem, { INavBarMenuItem } from './navBarMenuItem'
import NavBarMenuList from './navBarMenuList'
import Header from '../../header'
import { useSelector } from 'react-redux'
import Profile from './navBarProfile'
import Data from '../../menuData.json'
import { IUserState } from '../../interfaces'
import MegaMenu from './navBarMegaMenu'
import { baseURLSubmenu } from '../../utils'

export default function NavBar() {
  const { isAdmin } = useSelector((state: { userAccount: IUserState }) => state.userAccount)
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Header />
      <AppBar elevation={0} position='static' style={{ alignItems: 'center', background: '#444' }}>
        <Toolbar style={{ minHeight: 48 }}>
          <IconButton disableTouchRipple className='iconbtn-navbar' component={Link} to='/'>
            <img
              width='16px'
              alt='Stuller Europe'
              title='Stuller Europe'
              src={require('../../img/stuller-s-gold.svg').default}
            />
          </IconButton>

          <div style={{ margin: '0 12px 0 8px' }}>
            <NavBarMenuList title='Products' width='100vw' borderRadius='0 0 4px 4px'>
              <MegaMenu />
            </NavBarMenuList>
            <NavBarMenuList title='Services'>
              {Data.Services.map((data: INavBarMenuItem, key: number) => (
                <NavBarMenuItem title={data.title} url={baseURLSubmenu(data.url, data.title, 'services')} key={key} />
              ))}
            </NavBarMenuList>
            <Button disableTouchRipple component={Link} to='/contact' className='btn-navbar'>
              Contact
            </Button>
            {isAdmin && (
              <Button disableTouchRipple component={Link} to='/admin' className='btn-navbar'>
                Admin
              </Button>
            )}
          </div>
          <Profile />
        </Toolbar>
      </AppBar>
    </Box>
  )
}
