import { Stack, Container, Button, Typography, Grid } from '@mui/material'
import { ButtonWhite } from '../../styles'

export default function FeaturedBlock() {
  return (
    <Stack pt={2} pb={4}>
      <Container>
        <Grid container spacing={2} display='flex' justifyContent='center' alignItems='stretch'>
          <Grid item xs={12} md={12}>
            <Stack
              height='100%'
              justifyContent='center'
              style={{
                minHeight: 200,
                borderRadius: 16,
                padding: 32,
                background: 'url(https://meteor.stullercloud.com/das/115283688)',
                backgroundPosition: 'left bottom',
                backgroundSize: 'cover'
              }}
            >
              <Stack direction='column' alignItems='flex-start'>
                <img
                  style={{ marginBottom: 8, height: 40 }}
                  alt='Lab-Grown Diamonds Featured Stuller'
                  src={require('../../img/lab-grown-diamonds-featured-logo.png')}
                />
                <Typography align='left' variant='h3' style={{ color: '#eee', marginBottom: 16 }}>
                  Stuller Lab-Grown Diamonds™
                </Typography>
                <ButtonWhite
                  variant='outlined'
                  href={`discover?url=lab-grown-diamonds-info&title=${encodeURI('Stuller Lab-Grown Diamonds™')}`}
                >
                  Discover Now
                </ButtonWhite>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              height='100%'
              justifyContent='center'
              alignItems='flex-start'
              style={{
                borderRadius: 16,
                padding: 32,
                background: 'url(https://meteor.stullercloud.com/das/115283690)',
                backgroundPosition: 'left bottom',
                backgroundSize: 'cover'
              }}
            >
              <Typography
                color='primary'
                variant='h3'
                style={{ color: 'secondary', marginBottom: '1rem', maxWidth: 200 }}
              >
                Diamond Wedding Bands
              </Typography>
              <Button
                variant='outlined'
                color='primary'
                href={`discover?url=browse/featured-items/navigation/modern-brilliance/bridal/wedding-bands/&title=${encodeURI(
                  'Diamond Wedding Bands'
                )}`}
              >
                Discover Now
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              height='100%'
              alignItems='flex-end'
              justifyContent='center'
              style={{
                borderRadius: 16,
                padding: 32,
                background: 'url(https://meteor.stullercloud.com/das/115283691)',
                backgroundPosition: 'right bottom',
                backgroundSize: 'cover'
              }}
            >
              <Typography align='right' variant='h3' style={{ color: '#eee', marginBottom: '1rem', maxWidth: 200 }}>
                New Fine Jewelry Styles
              </Typography>
              <ButtonWhite
                variant='outlined'
                size='medium'
                disableElevation
                href={`discover?url=browse/new/jewelry/&title=${encodeURI('New Fine Jewelry Styles')}`}
              >
                Discover Now
              </ButtonWhite>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Stack>
  )
}
