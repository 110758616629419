import { CheckCircleOutline } from '@mui/icons-material'
import { Button, Container, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const RegistrationCompleted = () => {
  const navigate = useNavigate()
  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <CheckCircleOutline color='success' sx={{ fontSize: 80 }} style={{ marginTop: 40, marginBottom: 8 }} />
      <Typography variant='h4' gutterBottom>
        Thank you
      </Typography>
      <Typography gutterBottom>Your Application has been submittted successfully</Typography>
      <Typography gutterBottom>You will receive an email confirming your application.</Typography>
      <Typography>Welcome to Stuller Europe!</Typography>
      <Button
        variant='outlined'
        color='secondary'
        style={{ marginTop: 20, marginBottom: 32 }}
        onClick={() => navigate('/')}
      >
        Go to Homepage
      </Button>
    </Container>
  )
}

export default RegistrationCompleted
