import { Stack, Container, Grid, Typography, Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import './legal.css'
import Topic from './topic'
import { PlaylistAddCheckOutlined, LockOpenOutlined, CookieOutlined } from '@mui/icons-material'

export default function Legal() {
  return (
    <>
      <Stack mt={6} mb={12}>
        <Container className='legal-wrapper'>
          <Typography variant='h2' align='center' color='primary'>
            Stuller Europe Legal Policies
          </Typography>
          <Divider />
          <Typography color='primary' pb={3}>
            STULLER EUROPE SLU is committed to your trust. Read our customer policies for a clear explanation of how we
            manage your license rights, license legal FAQ's and how we collect and use your information.
          </Typography>

          <Typography variant='h3' color='primary' gutterBottom>
            Choose a Topic:
          </Typography>
          <Grid container columnSpacing={2} mt={{ xs: 2, md: 3 }} mb={{ xs: 0, md: 4 }}>
            <Grid item xs={12} md={4} mb={{ xs: 4, md: 0 }}>
              <Topic title='Privacy' link='/legal/privacy' icon={<LockOpenOutlined color='secondary' />} />
            </Grid>
            <Grid item xs={12} md={4} mb={{ xs: 4, md: 0 }}>
              <Topic title='Cookies' link='/legal/cookies' icon={<CookieOutlined color='secondary' />} />
            </Grid>
            <Grid item xs={12} md={4} mb={{ xs: 4, md: 0 }}>
              <Topic title='Terms of Use' link='/legal/terms' icon={<PlaylistAddCheckOutlined color='secondary' />} />
            </Grid>
          </Grid>
          <Typography color='primary' gutterBottom>
            If you have any questions not resolved in the above list or you want to learn more about them, please{' '}
            <Link to='/contact'>contact us</Link>.
          </Typography>
        </Container>
      </Stack>
    </>
  )
}
