import React, { useState } from 'react'
import { Stack, Container, Button, Typography, TextField } from '@mui/material'
import { postMemberMailchimp } from '../../services'
import { addNotification } from '../../customHooks/notifications'
import { NotificationMessages, NotificationType } from '../../constants/notifications'

export default function SubscribeBlock() {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)

  const handleSubscribeClick = async () => {
    if (validateEmail(email)) {
      setEmailError(false)
      try {
        await postMemberMailchimp(email)
        addNotification(NotificationType.success, NotificationMessages.SUCCESS_MAILCHIMP_SUBSCRIBE)
      } catch (error: any) {
        addNotification(NotificationType.danger, error.message)
      }
    } else {
      setEmailError(true)
    }
  }

  const validateEmail = (value: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i
    return emailRegex.test(value)
  }

  return (
    <>
      <Stack justifyContent={'center'}>
        <Container>
          <Stack
            alignItems={'center'}
            maxWidth={600}
            margin={'auto'}
            sx={{
              p: { xs: 2, md: 4, lg: 8 },
              py: { xs: 4, md: 8, lg: 8 }
            }}
          >
            <Typography variant='h2' align='center' color={'primary'} pb={2}>
              Keep connected with us
            </Typography>
            <Typography align='center' color={'primary'} pb={2}>
              Subscribe to our Newsletter
            </Typography>

            <TextField
              fullWidth
              label='Email'
              variant='outlined'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError}
              helperText={emailError ? 'Invalid email' : ''}
              sx={{ paddingBottom: 2 }}
            />
            <Button variant='contained' color='primary' onClick={handleSubscribeClick}>
              Subscribe Now
            </Button>
          </Stack>
        </Container>
      </Stack>
    </>
  )
}
