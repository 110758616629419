import { useState, useEffect } from 'react'
import { Container } from '@mui/material'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { useDispatch } from 'react-redux/es/exports'
import '@aws-amplify/ui-react/styles.css'
import { useNavigate } from 'react-router'
import AreYouRegistered from '../components/AreYouRegisterd'
import Loading from '../components/Loading'
import { signInUser } from '../services'
import { NotificationMessages, NotificationType } from '../constants/notifications'
import { addNotification } from '../customHooks/notifications'

const Login = () => {
  const [loading, setLoading] = useState(true)
  const { route } = useAuthenticator((context) => [context.authStatus])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleLogin = async () => {
    setLoading(true)

    const user = await signInUser(dispatch)

    if (user.isAdmin && user.userName) {
      navigate('/admin')
      addNotification(NotificationType.success, NotificationMessages.SUCCES_USER_AUTHENTICATED)
    } else if (!user.isAdmin && user.userName) {
      navigate('/')
      addNotification(NotificationType.success, NotificationMessages.SUCCES_USER_AUTHENTICATED)
    }

    setLoading(false)
  }

  useEffect(() => {
    handleLogin()
  }, [route])

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2
      }}
    >
      {loading && <Loading />}
      {!loading && route !== 'authenticated' && (
        <>
          <Authenticator hideSignUp={true} />
          <AreYouRegistered />
        </>
      )}
    </Container>
  )
}

export default Login
