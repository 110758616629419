import { NOTIFICATION_TYPE } from 'react-notifications-component'

interface INotificationType {
  success: NOTIFICATION_TYPE | undefined
  danger: NOTIFICATION_TYPE | undefined
  info: NOTIFICATION_TYPE | undefined
  default: NOTIFICATION_TYPE | undefined
  warning: NOTIFICATION_TYPE | undefined
}

export const enum NotificationMessages {
  SUCCESS_CHANGES_SAVED = 'Changes saved successfully',
  SUCCES_USER_AUTHENTICATED = 'You have been successfully authenticated',
  SUCCESS_MAILCHIMP_SUBSCRIBE = 'Thanks for Subscribe to our newsletter',
  ERROR_DOCUMENT_NO_EXIST = "This document doesn't exist",
  ERROR_FILE_TYPE = 'Only PDF, PNG, JPG, JPEG, GIF and BMP are allowed',
  ERROR_EMAIL_ACTIVATION = 'Error on sending activation email'
}

export const NotificationType: INotificationType = {
  success: 'success',
  danger: 'danger',
  info: 'info',
  default: 'default',
  warning: 'warning'
}
