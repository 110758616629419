import { Button, Container, Typography, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

const Error404 = () => {
  return (
    <Container>
      <Stack alignItems='center' justifyContent='center' textAlign='center' maxWidth={680} margin='4rem auto'>
        <Typography variant='h3' gutterBottom>
          Whoops! (404 Error)
        </Typography>
        <img
          style={{ maxWidth: '100%', margin: '1rem' }}
          src={'https://assets.stullercloud.com/web/static/i/server/404.png'}
          alt='404 error'
        />
        <Stack textAlign='left' mb={3}>
          <Typography gutterBottom>We're Sorry...</Typography>
          <Typography gutterBottom>The page you are looking for doesn't seem to exist.</Typography>
          <Typography>
            You may be able to find the information you were looking for with one of these links, or try using the
            search bar.
          </Typography>
        </Stack>
        <Button component={Link} to='/' variant='contained'>
          Back to Home
        </Button>
      </Stack>
    </Container>
  )
}
export default Error404
