import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux/es/exports'
import './index.css'
import App from './App'
import store from './redux/store'
import { Authenticator } from '@aws-amplify/ui-react'
import { CookiesProvider } from 'react-cookie'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <Authenticator.Provider>
          <App />
        </Authenticator.Provider>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>
)
