import { Stack } from '@mui/material'

export default function IframeCategory(props: { url: string; title: string }) {
  return (
    <Stack>
      <div style={{ height: '600px', minHeight: '100vh' }}>
        <iframe
          title={props.title}
          style={{
            width: '1px',
            minWidth: '100%',
            border: 'none',
            height: '100%'
          }}
          width='100%'
          sandbox='allow-scripts allow-forms allow-same-origin'
          src={props.url}
        ></iframe>
      </div>
    </Stack>
  )
}
