import React from 'react'
import { Container, Stack, Button, Typography } from '@mui/material'

export default function Buttons() {
  return (
    <div>
      <Stack>
        <Container>
          <Stack alignItems={'center'} style={{ paddingTop: 50, paddingBottom: 50 }}>
            <Stack alignItems={'center'} style={{ marginBottom: 15 }}>
              <Typography>Primary Text</Typography>
              <div style={{ borderTop: '1px solid #eee', marginTop: '5px' }}>
                <Button color='primary' variant='text' size='small' disableRipple style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='primary' variant='text' size='medium' disableRipple style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='primary' variant='text' size='large' disableRipple style={{ margin: 10 }}>
                  Button
                </Button>
              </div>
            </Stack>
            <Stack alignItems={'center'} style={{ marginBottom: 15 }}>
              <Typography>Secondary Text</Typography>
              <div style={{ borderTop: '1px solid #eee', marginTop: '5px' }}>
                <Button color='secondary' variant='text' size='small' disableRipple style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='secondary' variant='text' size='medium' disableRipple style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='secondary' variant='text' size='large' disableRipple style={{ margin: 10 }}>
                  Button
                </Button>
              </div>
            </Stack>
            <Stack alignItems={'center'} style={{ marginBottom: 15 }}>
              <Typography>Primary Outlined</Typography>
              <div style={{ borderTop: '1px solid #eee', marginTop: '5px' }}>
                <Button color='primary' variant='outlined' size='small' style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='primary' variant='outlined' size='medium' style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='primary' variant='outlined' size='large' style={{ margin: 10 }}>
                  Button
                </Button>
              </div>
            </Stack>
            <Stack alignItems={'center'} style={{ marginBottom: 15 }}>
              <Typography>Secondary Outlined</Typography>
              <div style={{ borderTop: '1px solid #eee', marginTop: '5px' }}>
                <Button color='secondary' variant='outlined' size='small' style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='secondary' variant='outlined' size='medium' style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='secondary' variant='outlined' size='large' style={{ margin: 10 }}>
                  Button
                </Button>
              </div>
            </Stack>
            <Stack alignItems={'center'} style={{ marginBottom: 15 }}>
              <Typography>Primary Contained</Typography>
              <div style={{ borderTop: '1px solid #eee', marginTop: '5px' }}>
                <Button color='primary' variant='contained' size='small' style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='primary' variant='contained' size='medium' style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='primary' variant='contained' size='large' style={{ margin: 10 }}>
                  Button
                </Button>
              </div>
            </Stack>
            <Stack alignItems={'center'} style={{ marginBottom: 15 }}>
              <Typography>Secondary Contained</Typography>
              <div style={{ borderTop: '1px solid #eee', marginTop: '5px' }}>
                <Button color='secondary' variant='contained' size='small' style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='secondary' variant='contained' size='medium' style={{ margin: 10 }}>
                  Button
                </Button>
                <Button color='secondary' variant='contained' size='large' style={{ margin: 10 }}>
                  Button
                </Button>
              </div>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </div>
  )
}
