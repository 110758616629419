import { Stack, Container, Typography, Divider } from '@mui/material'
import './legal.css'

export default function Cookies() {
  return (
    <Stack mt={6} mb={12}>
      <Container className='legal-wrapper'>
        <Typography variant='h2' align='center' color='primary'>
          Cookies
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Cookies Policy
        </Typography>
        <Typography color='primary' gutterBottom>
          Last updated: May 07, 2023
        </Typography>
        <Typography color='primary' gutterBottom>
          STULLER EUROPE SLU ('us', 'we', or 'our') uses cookies on the https://www.stuller.eu website (the 'Service').
          By using the Service, you consent to the use of cookies.
        </Typography>
        <Typography color='primary' gutterBottom>
          Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may
          use cookies on the Service, your choices regarding cookies and further information about cookies.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          What are cookies
        </Typography>
        <Typography color='primary' gutterBottom>
          Cookies are small pieces of text sent to your web browser by a website you visit. A cookie file is stored in
          your web browser and allows the Service or a third-party to recognize you and make your next visit easier and
          the Service more useful to you.
        </Typography>
        <Typography color='primary' gutterBottom>
          Cookies can be 'persistent' or 'session' cookies. Persistent cookies remain on your personal computer or
          mobile device when you go offline, while session cookies are deleted as soon as you close your web browser.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          How STULLER EUROPE SLU uses cookies
        </Typography>
        <Typography color='primary' gutterBottom>
          When you use and access the Service, we may place a number of cookies files in your web browser.
        </Typography>
        <Typography color='primary' gutterBottom>
          We use cookies for the following purposes:
        </Typography>
        <ul className='legal-ul'>
          <li>To enable certain functions of the Service.</li>
          <li>To provide analytics.</li>
          <li>To store your preferences.</li>
        </ul>
        <Typography color='primary' gutterBottom>
          We use both session and persistent cookies on the Service and we use different types of cookies to run the
          Service:
        </Typography>
        <ul className='legal-ul'>
          <li>
            Essential cookies. We may use cookies to remember information that changes the way the Service behaves or
            looks, such as a user's language preference on the Service.
          </li>
          <li>
            Accounts-related cookies. We may use accounts-related cookies to authenticate users and prevent fraudulent
            use of user accounts. We may use these cookies to remember information that changes the way the Service
            behaves or looks, such as the 'remember me' functionality.
          </li>
          <li>
            Analytics cookies. We may use analytics cookies to track information how the Service is used so that we can
            make improvements. We may also use analytics cookies to test new advertisements, pages, features or new
            functionality of the Service to see how our users react to them.
          </li>
        </ul>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Third-party cookies
        </Typography>
        <Typography color='primary' gutterBottom>
          In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of
          the Service, deliver advertisements on and through the Service, and so on.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          What are your choices regarding cookies
        </Typography>
        <Typography color='primary' gutterBottom>
          If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the
          help pages of your web browser.
        </Typography>
        <Typography color='primary' gutterBottom>
          Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of
          the features we offer, you may not be able to store your preferences, and some of our pages might not display
          properly.
        </Typography>
        <ul className='legal-ul'>
          <li>
            For the Chrome web browser, please{' '}
            <a href='https://support.google.com/accounts/answer/32050' target='blank'>
              visit this page
            </a>{' '}
            from Google.
          </li>
          <li>
            For the Internet Explorer web browser, please{' '}
            <a href='http://support.microsoft.com/kb/278835' target='blank'>
              visit this page
            </a>{' '}
            from Microsoft.
          </li>
          <li>
            For the Firefox web browser, please{' '}
            <a href='https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored' target='blank'>
              visit this page
            </a>{' '}
            from Mozilla.
          </li>
          <li>
            For the Safari web browser, please{' '}
            <a href='https://support.apple.com/kb/PH21411?locale=en_US' target='blank'>
              visit this page
            </a>{' '}
            from Apple.
          </li>
          <li>Or any other web browser, please visit your web browser's official web pages.</li>
        </ul>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Where can you find more information about cookies
        </Typography>
        <Typography color='primary' gutterBottom>
          You can learn more about cookies and the following third-party websites:
        </Typography>
        <ul className='legal-ul'>
          <li>
            <a href='http://www.allaboutcookies.org/' target='blank'>
              All About Cookies
            </a>
            .
          </li>
          <li>
            <a href='http://www.networkadvertising.org/' target='blank'>
              Network Advertising Initiative
            </a>
            .
          </li>
        </ul>
      </Container>
    </Stack>
  )
}
