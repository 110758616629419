import React from 'react'
import FeaturedBlock from './featuredblock'
import BenefitsBlock from './benefitsblock'
import RegisterBlock from './registerblock'
import HomeSliders from './homeSliders'
import SubscribeBlock from './subscribeBlock'

export default function Home() {
  return (
    <>
      <HomeSliders />
      <FeaturedBlock />
      <BenefitsBlock />
      <RegisterBlock />
      <SubscribeBlock />
    </>
  )
}
