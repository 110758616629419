import { Stack, Container, Typography, Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import './legal.css'

export default function UserTerms() {
  return (
    <Stack mt={6} mb={12}>
      <Container className='legal-wrapper'>
        <Typography variant='h2' align='center' color='primary'>
          Terms of Use
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          The following Terms and Conditions apply to the use of this Web site as well as all transactions conducted
          through the site.
        </Typography>
        <Typography color='primary' gutterBottom>
          PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. THESE TERMS AND CONDITIONS ("T&C") MAY HAVE CHANGED
          SINCE YOUR LAST VISIT TO THE SITE. BY USING THE SITE, YOU INDICATE YOUR ACCEPTANCE OF THESE T&C. IF YOU DO NOT
          ACCEPT THESE T&C, THEN DO NOT USE THE SITE.
        </Typography>
        <Typography color='primary' gutterBottom>
          Stuller.eu ("Site") is owned by STULLER EUROPE SLU ("Stuller Europe") located in Mataró, Barcelona and the
          store is provided free of charge to users who agree to abide by these T&C. Stuller Europe reserves the right
          to change the nature of this relationship at any time, and to revise these T&C from time to time as Stuller
          Europe sees fit. Users who violate these T&C will have their access canceled and they may permanently be
          banned from using the Site. Users should check these T&C periodically. By using the Site after we post changes
          to these T&C, you agree to accept those changes, whether or not you actually reviewed them.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Personal Information & Privacy
        </Typography>
        <Typography color='primary' gutterBottom>
          Stuller Europe will use and protect your data, such as your name and address. Please be aware that your
          browser must be enabled to accept cookies in order for you to use certain Materials and Services or to
          purchase from the Stuller Europe online store. Stuller Europe also sends promotional emails and service
          related emails related to your account. If you no longer wish to receive these emails, you may opt out of them
          by calling <a href='tel:+34.937.547.774'>+34 937 547 774</a> (Phone),{' '}
          <a href='tel:+34.722.687.266'>+34 722 687 266</a> (WhatsApp) or by emailing{' '}
          <a href='mailto:europe@stuller.com'>europe@stuller.com</a>.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Return Policy
        </Typography>
        <Typography color='primary' gutterBottom>
          If you are unsatisfied with a purchase from this store, or have questions about your product or order, please
          contact Stuller Europe directly by calling <a href='tel:+34.937.547.774'>+34 937 547 774</a> (Phone),{' '}
          <a href='tel:+34.722.687.266'>+34 722 687 266</a> (WhatsApp) or by emailing{' '}
          <a href='mailto:europe@stuller.com'>europe@stuller.com</a>.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Shipping & Delivery
        </Typography>
        <Typography color='primary' gutterBottom>
          The risk of loss and title for all merchandise ordered on this Web site pass to you when the merchandise is
          delivered to the shipping carrier. Customs and import duties may be applied to International orders when the
          shipment reaches its destination. Such charges are the responsibility of the recipient of your order and vary
          from country to country. Contact your local customs office for details. Shipping laws are different in each
          country. It is your responsibility to check with your Customs office to verify whether the country to which
          you are shipping permits the shipment of your products. Stuller Europe is not responsible for any direct,
          indirect, punitive, or consequential damages that arise from improper international shipping practices.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Users/Participants
        </Typography>
        <Typography color='primary' gutterBottom>
          We do not discriminate on the basis of age, race, national origin, gender, sexual orientation or religion.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Cookies
        </Typography>
        <Typography color='primary' gutterBottom>
          "Cookies" are small encrypted data files (containing references to user ID information supplied by the user
          and stored on our secure server) that may write to your hard drive once you have accessed a website. Stuller
          Europe uses cookies to enhance your shopping experience with us. Cookies are used to keep track of your
          shopping cart and to save your password so you do not have to reenter it each time you visit the Site. For
          more information contact Stuller Europe directly by calling <a href='tel:+34.937.547.774'>+34 937 547 774</a>{' '}
          (Phone), <a href='tel:+34.722.687.266'>+34 722 687 266</a> (WhatsApp) or by emailing{' '}
          <a href='mailto:europe@stuller.com'>europe@stuller.com</a>. You many also review our{' '}
          <Link to='/legal/privacy'>Privacy policy</Link>.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Copyrights, Trademarks & Restrictions
        </Typography>
        <Typography color='primary' gutterBottom>
          The material included herein, including site design, text, graphics, and the selection and arrangement thereof
          are copyrighted © by STULLER EUROPE SLU. ALL RIGHTS RESERVED. Stuller.eu®, Stuller Europe®, other trademarks
          and all page headers, custom graphics, and custom icons are service marks and trademarks of STULLER EUROPE
          SLU. All other trademarks, product names, and company names or logos cited herein are the property of their
          respective owners.
        </Typography>
        <Typography color='primary' gutterBottom>
          The Site and all materials on the Site, including, but not limited to, images, illustrations, posts, audio
          clips and video clips (the "Materials") are the property of Stuller Europe and its affiliates and licensors
          and are protected from unauthorized copying and dissemination by copyrights that are owned or licensed by
          Stuller Europe and by copyright law, trademark law, international conventions and other intellectual property
          laws. You may not reproduce, perform, create derivative works from, republish, upload, post, transmit, or
          distribute in any way whatsoever any Materials from the Site (or any other website owned or operated by
          Stuller Europe or its subsidiaries or affiliates) without Stuller Europe' prior written permission. However,
          you may print in hard copy portions of the Site with the sole intent of placing an order with Stuller Europe
          or applying for credit. Printing Materials for any other reason, or transferring Materials for use on any
          other website, or the modification, distribution, or republication of Materials without Stuller Europe' prior
          written permission is strictly prohibited. Any modification of Materials, or any portion thereof, or use of
          Materials for any other purpose constitutes an infringement of trademark or other proprietary rights of
          Stuller Europe or third parties.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Links
        </Typography>
        <Typography color='primary' gutterBottom>
          Stuller Europe is not responsible for the content of any unaffiliated websites that may be linked to or from
          the Site. These links are provided for your convenience only and you access them at your own risk. Any other
          website accessed from the Site is independent from Stuller Europe, and Stuller Europe has no control over the
          content of that other website. In addition, a link to any other website does not imply that Stuller Europe
          endorses or accepts any responsibility for the content or use of such other website. In no event shall any
          reference to any third party or third party product or service be construed as an approval or endorsement by
          Stuller Europe of that third party or of any product or service provided by a third party.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Privacy Issues
        </Typography>
        <Typography color='primary' gutterBottom>
          Stuller Europe is concerned about privacy matters for its customers. To learn more about how we protect
          customer information, please review our <Link to='/legal/privacy'>Privacy policy</Link>.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Product and Services Information
        </Typography>
        <Typography color='primary' gutterBottom>
          Most products (and some services) offered on the Site are available in select Stuller Europe stores throughout
          the United States, however, prices and availability may vary. Prices shown on the Site apply to products and
          services on the Site. Price and availability are subject to change without notice.
        </Typography>
        <Typography color='primary' gutterBottom>
          We attempt to display all products shown on the Site in an accurate manner, however, because of the technical
          differences in the equipment of our customers, we cannot be responsible for photographic differences in size,
          shape and color of the products.
        </Typography>
        <Typography color='primary' gutterBottom>
          The receipt of an email order confirmation is simply a recognition that we have received your requested order,
          and does not constitute an offer to sell. We reserve the right to limit the quantity of any item sold, or
          prohibit a sale altogether, including but not limited to prohibiting sales to resellers. We also reserve the
          right, in our sole discretion, to correct printing/pricing errors and change our delivery policy and delivery
          fees.
        </Typography>
        <Divider />

        <Typography variant='h3' color='primary' gutterBottom>
          Notice and Take Down Procedures
        </Typography>
        <Typography color='primary' gutterBottom>
          If you believe any Materials on the Site infringe your copyright or trademark rights, you may request such
          Materials be removed by following the notice and take down procedures of the Digital Millennium Copyright Act.
          To follow those procedures, contact Stuller Europe' copyright agent (identified below) and provide the
          following information: A clear statement identifying the works, or other materials believed to be infringed.
        </Typography>
        <Typography color='primary' gutterBottom>
          A statement from the copyright holder or authorized representative that the Materials are believed to be
          infringing.
        </Typography>
        <Typography color='primary' gutterBottom>
          Sufficient information about the location of the allegedly infringing Materials so that Stuller Europe can
          find and verify its existence.
        </Typography>
        <Typography color='primary' gutterBottom>
          Your name, telephone number and e-mail address.
        </Typography>
        <Typography color='primary' gutterBottom>
          A statement from you under penalty of perjury that the information supplied is accurate, and that you are
          authorized to act on the copyright owner's behalf.
        </Typography>
        <Typography color='primary' gutterBottom>
          A signature or the electronic equivalent from the copyright holder or authorized representative.
        </Typography>
        <Typography color='primary' gutterBottom>
          Stuller Europe' agent for notice of copyright issues on the Site can be reached as follows:
        </Typography>
        <ul className='legal-ul'>
          <li>STULLER EUROPE SLU</li>
          <li>Moll de Ponent s/n</li>
          <li>Mataró (08304), Barcelona, Spain</li>
          <li>Attn: Copyright Infringement</li>
        </ul>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Conduct of Users
        </Typography>
        <Typography color='primary' gutterBottom>
          You agree that you will not:
        </Typography>
        <ul className='legal-ul'>
          <li>
            Transmit via or through the Site any information, data, text, files, links, software, chat, communication or
            other materials that is or which Stuller Europe considers to be unlawful, harmful, threatening, abusive,
            harassing, defamatory, vulgar, obscene, racially or ethnically hateful or otherwise objectionable.
          </li>
          <li>Attempt to transmit any “virus”, “trojan horse” or other software destruction or disruption device.</li>
          <li>
            Intentionally or unintentionally violate any applicable local, state, national or international law,
            including but not limited to, any regulations having the force of law while using or accessing the Site or
            in connection with your use of the Site, in any manner.
          </li>
          <li>
            Invade the privacy or violate any personal or proprietary right (including intellectual property rights) of
            any person or entity.
          </li>
        </ul>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Indemnity
        </Typography>
        <Typography color='primary' gutterBottom>
          By using the Site, you agree to indemnify Stuller Europe for any and all claims, damages, losses, liabilities
          and causes of action (including expenses and attorneys' fees) incurred by them arising out of or relating to
          your breach or alleged breach of these T&C (including without limitation, claims made by third parties for
          infringement of intellectual property rights). You agree to cooperate as fully as reasonably required in the
          defense of any claim. Stuller Europe reserves the right to assume the exclusive defense and control of any
          matter otherwise subject to indemnification by you.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Disclaimer, Limitation of Liability
        </Typography>
        <Typography color='primary' gutterBottom>
          THE SITE IS PROVIDED BY STULLER EUROPE ON AN “AS IS” AND “AS AVAILABLE” BASIS. STULLER EUROPE MAKES NO
          REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO (1) THE OPERATION OF THE SITE, (2) THE
          QUALITY, ACCURACY, COMPLETENESS OR VALIDITY OF ANY MATERIALS ON THE SITE, OR THE INFORMATION, CONTENT,
          MATERIALS, OR PRODUCTS INCLUDED ON THE SITE, OR (3) WHETHER THE FUNCTIONS CONTAINED ON THE SITE WILL BE
          UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED, INCLUDING BUT NOT LIMITED TO, WARRANTIES OF
          TITLE, IMPLIED WARRANTIES OF MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. STULLER EUROPE IS NOT
          RESPONSIBLE FOR PRINTING OR TYPOGRAPHICAL ERRORS. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SITE IS AT YOUR
          SOLE RISK.
        </Typography>
        <Typography color='primary' gutterBottom>
          THIS SITE MAY ALSO CONTAIN FACTS, VIEWS, OPINIONS, STATEMENTS, AND RECOMMENDATIONS OF THIRD PARTY INDIVIDUALS
          AND ORGANIZATIONS. STULLER EUROPE DOES NOT REPRESENT OR ENDORSES THE ACCURACY, TIMELINESS, OR RELIABILITY OF
          ANY ADVICE, OPINION, STATEMENT, OR OTHER INFORMATION DISPLAYED, UPLOADED, OR DISTRIBUTED THROUGH THE SITE. YOU
          ACKNOWLEDGE THAT ANY RELIANCE ON ANY SUCH OPINION, ADVICE, STATEMENT, OR INFORMATION WILL BE AT YOUR SOLE
          RISK.
        </Typography>
        <Typography color='primary' gutterBottom>
          STULLER EUROPE IS NOT RESPONSIBLE OR LIABLE FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR OTHER
          INDIRECT DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE SITE OR THE INFORMATION CONTAINED
          ON THE SITE, EVEN IF THAT PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE
          TOTAL LIABILITY OF STULLER EUROPE TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION RESULTING FROM YOUR USE
          OF THE SITE, WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE) OR OTHERWISE, EXCEED ONE
          DOLLAR (US$1.00).
        </Typography>
        <Typography color='primary' gutterBottom>
          CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
          DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT
          APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Termination
        </Typography>
        <Typography color='primary' gutterBottom>
          Stuller Europe may, in its sole discretion, terminate or suspend your access to all or part of the Site, for
          any reason, including without limitation, breach of these T&C. In the event these T&C are terminated, the
          restrictions regarding Materials appearing on the Site, and the representations and warranties, indemnities,
          and limitations of liabilities set forth in these T&C will survive termination. In the event that you are
          unsatisfied with the services provided by Stuller Europe, your sole remedy is to terminate your use of the
          Site.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Jurisdiction
        </Typography>
        <Typography color='primary' gutterBottom>
          Stuller Europe operates the Site from its offices within the United States. Stuller Europe makes no
          representations that content and materials on the Site are legal or appropriate for use from outside the
          United States. If you choose to access the Site from other locations, you do so at your own risk and are
          responsible for compliance with any and all local laws. You may not use the Site in violation of U.S. export
          laws and regulations. These T&C will be governed by and construed in accordance with the laws of the State of
          Louisiana, as it is applied to agreements entered into and performed therein, and excluding (i) the United
          Nations Convention on Contracts for the International Sale of Goods; (ii) the 1974 Convention on the
          Limitation Period in the International Sale of Goods; and (iii) the Protocol amending the 1974 Convention,
          done at Vienna April 11, 1980. Any action brought to enforce these T&C or matters related to the Site will be
          brought in either the State or Federal Courts of the State of Louisiana; provided, however, that
          notwithstanding anything contained in these T&C to the contrary, Stuller Europe shall have the right to
          institute judicial proceedings against you or anyone acting by, through or under you, in order to enforce
          Stuller Europe' rights hereunder through reformation of contract, specific performance, injunction or similar
          equitable relief. Any claim or cause of action you have with respect to use of the Site must be commenced
          within one (1) year after the claim arises. If any provision of these T&C is deemed void, unlawful, or
          otherwise unenforceable for any reason, that provision will be severed from these T&C and the remaining
          provisions of these T&C will remain in force. These T&C constitute the entire agreement between you and
          Stuller Europe concerning your use of the Site.
        </Typography>
        <Divider />
        <Typography variant='h3' color='primary' gutterBottom>
          Comments, Complaints and Questions
        </Typography>
        <Typography color='primary' gutterBottom>
          We welcome your feedback about the Site. However, any comments, ideas, notes, messages, suggestions or other
          communications sent to the Site shall be and remain the exclusive property of Stuller Europe, and we may use
          all such communications in any manner, including reproducing, disclosing and publishing such communications,
          all without compensation to you. Should you have any questions or complaints regarding violations of these
          T&C, please contact us by calling <a href='tel:+34.937.547.774'>+34 937 547 774</a> (Phone),{' '}
          <a href='tel:+34.722.687.266'>+34 722 687 266</a> (WhatsApp) or by emailing{' '}
          <a href='mailto:europe@stuller.com'>europe@stuller.com</a>.
        </Typography>
      </Container>
    </Stack>
  )
}
