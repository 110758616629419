import { useSelector } from 'react-redux'
import { IShowCasePrice, IUserState } from '../interfaces'
import IframeCategory from './iframeCategory'

const ShowCasePrice = (props: IShowCasePrice) => {
  const { baseURL } = useSelector((state: { userAccount: IUserState }) => state.userAccount)

  return <IframeCategory title={props.title} url={`${baseURL}/${props.path}`} />
}

export default ShowCasePrice
