import './footer.css'
import { Stack, Container } from '@mui/material'
import PreFooter from './preFooter'
import ContentFooter from './footerContent'
import BottomFooter from './bottomFooter'
import useMediaQuery from '../../customHooks/screenSize'
import FooterMobile from './footerMobile'

export default function Footer() {
  const isMobile = useMediaQuery()
  return (
    <>
      <Stack className='footer-wrapper'>
        <Container>
          {isMobile ? <FooterMobile /> : <ContentFooter />}
          <PreFooter />
        </Container>
      </Stack>
      <Stack className='bottomfooter-wrapper'>
        <Container>
          <BottomFooter />
        </Container>
      </Stack>
    </>
  )
}
