import { useSelector } from 'react-redux'
import { IUserState } from '../interfaces'

const getIsAuthenticatedAndBaseUrl = () => {
  const { baseURL, isAuthenticated } = useSelector((state: { userAccount: IUserState }) => state.userAccount)
  return { baseURL, isAuthenticated }
}

export const linkTarget = () => {
  const { isAuthenticated } = getIsAuthenticatedAndBaseUrl()
  let target = '_self'
  if (isAuthenticated) {
    target = '_blank'
  }
  return target
}

export const baseURLSubmenu = (url: string, title: string, path: string) => {
  const { baseURL, isAuthenticated } = getIsAuthenticatedAndBaseUrl()
  let urlResult =
    path === 'services'
      ? `services?url=${url}&title=${encodeURI(title)}`
      : `/catalogue/submenus?url=${url}&title=${encodeURI(title)}`
  if (isAuthenticated) {
    urlResult = `${baseURL}/${url}`
  }
  return urlResult
}

export const baseURLCatalogue = () => {
  const { baseURL, isAuthenticated } = getIsAuthenticatedAndBaseUrl()
  let url = '/catalogue'
  if (isAuthenticated) {
    url = `${baseURL}/browse`
  }
  return url
}
