import { configureStore, combineReducers } from '@reduxjs/toolkit'
import userAccountReducer from './slices/userAccountSlice'

const rootReducer = combineReducers({
  userAccount: userAccountReducer
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export default store
