export const CURRENT_STAGE = process.env.REACT_APP_current_stage
export const BASE_URL = CURRENT_STAGE === 'prod' ? 'https://api.stuller.eu' : 'https://api-dev.stuller.eu'
export const RECAPTCHA_KEY = '6Lc_BJQmAAAAAFRvavMlj4z0wyOWP3-f6qQqgnGY'
export const LIST_ID = 'adbe787ac3'
export const REDUX_BASE_URL = 'https://stullereu-frame-categoryembed.jewelershowcase.com'
export const EMAIL_IDENTITY = 'europe@stuller.com'
export enum EmailTemplates {
  WELCOME = 'stullereu_registration_welcome',
  ACTIVATION = 'stullereu_account_activated'
}
