import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ITextField } from '../../../interfaces'

/**
 * Renders a Material-UI TextField component with additional validation and error handling.
 * @interface ITextField
 * @returns Render a MUI Textfield using react-hook-form "Controller"
 */
const TextFieldComponent = ({
  required,
  pattern,
  label,
  name,
  type,
  placeholder,
  fullWidth,
  defaultValue
}: ITextField) => {
  const [patternValidator, setPatternValidator] = useState<RegExp>()
  const [helperText, setHelperText] = useState<string | undefined>('')
  const [passwordMatchError, setPasswordMatchError] = useState(false)
  const [customError, setCustomError] = useState(false)
  const [confirmationPasswordFocused, setConfirmationPasswordFocused] = useState(false)
  const passwordFields = ['confirmPassword', 'password']
  const {
    control,
    setError,
    clearErrors,
    getValues,
    trigger,
    formState: { errors }
  } = useFormContext()

  const handleOnBlur = (value: string, onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void) => {
    trigger(name).then((isValid) => {
      if (isValid) {
        onBlur({ target: { value } } as React.ChangeEvent<HTMLInputElement>)
      }
    })

    if (passwordFields.includes(name)) {
      setConfirmationPasswordFocused(false)
    }
  }

  /**
   * Handles setting the confirmationPasswordFocused on true if name prop is include in passwordFields when textField gets focused
   */
  const handleOnFocus = (): void => {
    if (passwordFields.includes(name)) {
      setConfirmationPasswordFocused(true)
    }
  }

  /**
   * Handles setting the helper text for the input field, based on validation errors.
   */
  const handleHelperText = (): void => {
    setHelperText('')
    setCustomError(false)

    if (errors[name]?.type === 'required') {
      return setHelperText('Required')
    } else if (errors[name]?.type === 'validate') {
      return setHelperText('Text cannot contain blank spaces')
    } else if (errors[name]?.type === 'pattern') {
      if (pattern === 'email') {
        return setHelperText('Email invalid')
      }
      if (pattern === 'password') {
        return setHelperText(
          'Password must be minim 8 characters and contain at list one uppercase letter, one lowercase letter, and one digit'
        )
      }
    } else if (errors['confirmPassword']?.type === 'passwordNoEqual') {
      if (name === 'confirmPassword') {
        setCustomError(true)
        return setHelperText('Passwords are not the same')
      }
    }
  }

  /**
   * Compares the `password` and `confirmPassword` fields and sets an error if they do not match.
   */
  const passwordCompare = (): void => {
    const password = getValues('password')
    const confirmPassword = getValues('confirmPassword')

    if (confirmationPasswordFocused) {
      return
    }
    if (password !== confirmPassword && password.length && confirmPassword.length) {
      setPasswordMatchError(true)

      return setError('confirmPassword', { type: 'passwordNoEqual' })
    } else if (errors['confirmPassword']?.type === 'passwordNoEqual') {
      clearErrors('confirmPassword')
      setHelperText('')
    }
    setCustomError(false)
    return setPasswordMatchError(false)
  }

  /**
   * This effect validates the textField based on the defined rules
   */
  useEffect(() => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/

    passwordCompare()

    if (pattern === 'email') {
      setPatternValidator(emailPattern)
    } else if (pattern === 'password') {
      setPatternValidator(passwordPattern)
    }

    if (errors[name] || passwordMatchError) {
      return handleHelperText()
    }

    setHelperText('')
  }, [errors[name]?.type, passwordMatchError, confirmationPasswordFocused])

  return (
    <Controller
      rules={{
        required: required,
        pattern: patternValidator,
        validate: (value) => value.length === value.trim().length
      }}
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          size='small'
          {...field}
          onFocus={handleOnFocus}
          onBlur={(e) => {
            handleOnBlur(e.target.value, field.onBlur)
          }}
          defaultValue={defaultValue}
          sx={{ margin: 0 }}
          fullWidth={fullWidth}
          type={type}
          name={name}
          label={label}
          placeholder={placeholder || label}
          error={!!errors[name] || customError}
          helperText={helperText}
        />
      )}
    />
  )
}
export default TextFieldComponent
