import * as React from 'react'
import HomeBanner from '../Home/homeBanner'
import Breadcrumbs from '../../components/breadcrumbs'

export default function Catalogue() {
  return (
    <>
      <Breadcrumbs />
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        <HomeBanner background='blue' text='Text 1' />
        <HomeBanner background='red' text='Text 2' />
        <HomeBanner background='green' text='Text 3' />
      </div>
    </>
  )
}
