import * as React from 'react'
import { Stack } from '@mui/material'
import ShowCasePrice from '../../components/ShowCasePrices'

export default function ToolsAndSupplies() {
  return (
    <Stack>
      <div style={{ width: '100%', height: '600px', minHeight: '100vh' }}>
        <ShowCasePrice title='Tools and Supplies' path={'browse/tools-and-supplies'} />
      </div>
    </Stack>
  )
}
