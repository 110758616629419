import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from './containers/Home/home'
import Catalogue from './containers/Catalogue/catalogue'
import WeddingBands from './containers/Catalogue/wedding-bands'
import Engagements from './containers/Catalogue/engagements'
import ChainAndCord from './containers/Catalogue/chain-and-cord'
import Jewelry from './containers/Catalogue/jewelry'
import Mountings from './containers/Catalogue/mountings'
import Findings from './containers/Catalogue/findings'
import Diamonds from './containers/Catalogue/diamonds'
import Gemstones from './containers/Catalogue/gemstones'
import Metals from './containers/Catalogue/metals'
import ToolsAndSupplies from './containers/Catalogue/tools-and-supplies'
import PackagingAndDisplays from './containers/Catalogue/packaging-and-displays'
import Buttons from './components/buttons'
import RegistrationMain from './containers/Registration/RegistrationMain'
import Contact from './containers/Contact/contact'
import Legal from './containers/Legal/legal'
import Privacy from './containers/Legal/privacy'
import Cookies from './containers/Legal/cookies'
import Terms from './containers/Legal/userTerms'
import Login from './containers/Login'
import AdminMain from './containers/Admin/AdminMain'
import AdminListUserAttributes from './components/Admin/AdminListUserAttributes'
import ProtectedRoute from './components/ProtectedRoute'
import useMediaQuery from './customHooks/screenSize'
import AdminEditUserDetails from './components/Admin/AdminEditUserDetails'
import Error404 from './components/Common/Error404'
import Iframe from './containers/Iframe'

const AppRoutes = () => {
  const { pathname } = useLocation()
  const isMobile = useMediaQuery()
  const changeRoute = (event: any) => {
    console.log('changeRoute', event.detail)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    window.addEventListener('changeRoute', changeRoute)
    return () => {
      window.removeEventListener('changeRoute', changeRoute)
    }
  }, [])

  {
    /* TODO: Component could be mapped through an array, due the list is the same for some components in the APP */
  }
  return (
    <div style={{ marginTop: isMobile ? 56 : 0 }}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/buttons' element={<Buttons />} />
        <Route path='/catalogue' element={<Catalogue />} />
        <Route path='/catalogue/submenus/*' element={<Iframe />} />
        <Route path='/catalogue/wedding-and-engagement/wedding-bands' element={<WeddingBands />} />
        <Route path='/catalogue/wedding-and-engagement' element={<Engagements />} />
        <Route path='/catalogue/chain-and-cord' element={<ChainAndCord />} />
        <Route path='/catalogue/jewelry' element={<Jewelry />} />
        <Route path='/catalogue/mountings' element={<Mountings />} />
        <Route path='/catalogue/findings' element={<Findings />} />
        <Route path='/catalogue/diamonds' element={<Diamonds />} />
        <Route path='/catalogue/gemstones' element={<Gemstones />} />
        <Route path='/catalogue/metals' element={<Metals />} />
        <Route path='/catalogue/tools-and-supplies' element={<ToolsAndSupplies />} />
        <Route path='/catalogue/packaging-and-displays' element={<PackagingAndDisplays />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/registration' element={<RegistrationMain />} />
        <Route path='/legal' element={<Legal />} />
        <Route path='/legal/privacy' element={<Privacy />} />
        <Route path='/legal/cookies' element={<Cookies />} />
        <Route path='/legal/terms' element={<Terms />} />
        <Route path='/login' element={<Login />} />
        <Route path='/slider/*' element={<Iframe />} />
        <Route path='/discover/*' element={<Iframe />} />
        <Route path='/services/*' element={<Iframe />} />
        <Route path='/admin/details/:userName' element={<AdminListUserAttributes />} />
        <Route path='/admin/edit/:userName' element={<AdminEditUserDetails />} />
        <Route
          path='/admin'
          element={
            <ProtectedRoute>
              <AdminMain />
            </ProtectedRoute>
          }
        />
        <Route path='/admin/details/:userName' element={<AdminListUserAttributes />} />
        <Route path='/admin/edit/:userName' element={<AdminEditUserDetails />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </div>
  )
}

export default AppRoutes
