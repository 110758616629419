import { Stack, Container, Typography, Link } from '@mui/material'
import './breadcrumbs.css'
import Breadcrumbs from '@mui/material/Breadcrumbs'

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault()
  console.info('You clicked a breadcrumb.')
}

export default function CustomSeparator() {
  const breadcrumbs = [
    <Link underline='none' key='1' href='/' onClick={handleClick}>
      Home
    </Link>,
    <Link underline='none' key='2' href='' onClick={handleClick}>
      Child
    </Link>,
    <Typography key='3'>Current</Typography>
  ]

  return (
    <Stack className='breadcrumbs-wrapper'>
      <Container>
        <Breadcrumbs aria-label='breadcrumb'>{breadcrumbs}</Breadcrumbs>
      </Container>
    </Stack>
  )
}
