import * as React from 'react'
import { AppBar } from '@mui/material'
import { Link } from 'react-router-dom'

export default function Header() {
  return (
    <AppBar position='static' style={{ background: '#eee', display: 'flex', alignItems: 'center' }}>
      <Link to='/' style={{ width: 200, display: 'flex', padding: 20 }}>
        <img
          style={{ width: '100%' }}
          alt='Stuller Europe logo'
          src={require('./img/stuller-europe-horizontal-logo.svg').default}
        />
      </Link>
    </AppBar>
  )
}
