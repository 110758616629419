import * as React from 'react'
import { Typography, Stack } from '@mui/material'
import './home.css'

interface IHomeBanner {
  styleName?: string
  title?: string
  color?: string
  description?: string
  colorDescription?: string
  image?: string
  width?: number
  height?: number
  marginBottom?: string
  background?: string
  text?: string
  // iconImage?: string
  // iconImageWidth?: number
  // iconAlt?: string
  // title: string
  // description?: string
  // image?: string
  // maxHeight?: string
  // alt: string
  children?: React.ReactNode
  // marginBottom?: string
  // marginTop?: string
  // startDate?: string
  // endDate?: string
  // startDateUTC?: IUTC
  // endDateUTC?: IUTC
  // color?: string
  // colorDescription?: string
  // descriptionFontSize?: string
  // styleName: string
  // height?: number
  // width?: number
  // iconImageMaxHeight?: number
  // variantTitle?: variant
}

export default function HomeBanner(props: IHomeBanner) {
  return (
    <Stack
      className='homeBanner-wrapper'
      alignItems='center'
      justifyContent='center'
      style={{ background: `url(${props.image})`, height: `${props.height}px` }}
    >
      <Stack
        className='homeBanner-content'
        alignItems='center'
        style={{
          marginTop: '-10rem'
        }}
      >
        <Typography variant='h1' style={{ marginBottom: '0.75rem', color: props.color }}>
          {props.title}
        </Typography>
        <Typography
          style={{
            color: props.colorDescription,
            fontSize: 'calc(16px + 2*(100vw - 20rem)/980)',
            fontWeight: '300',
            marginBottom: '0.75rem'
          }}
        >
          {props.description}
        </Typography>
        <Stack direction={'row'}>{props.children}</Stack>
      </Stack>
    </Stack>
  )
}
