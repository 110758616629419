import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UserType, AttributeType } from '@aws-sdk/client-cognito-identity-provider'
import { getUserPoolUsers, updateUser } from '../../services'
import { Box, Button, Grid, IconButton, TextField, Tooltip, Typography, Stack } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { ArrowBack } from '@mui/icons-material'
import Loading from '../Loading'
import { NotificationType, NotificationMessages } from '../../constants/notifications'
import { addNotification } from '../../customHooks/notifications'

const AdminEditUserDetails = () => {
  const [userData, setUserData] = useState<UserType | undefined>({})
  const [attributes, setAttributes] = useState<AttributeType[] | undefined>([])
  const [loading, setLoading] = useState(true)
  const { userName } = useParams()
  const navigate = useNavigate()
  const { control, handleSubmit } = useForm({
    defaultValues: {
      ...(attributes && Object.fromEntries(attributes.map((attribute) => [attribute.Name, attribute.Value])))
    }
  })

  const handleFormSubmit = async (data: Record<string, string>) => {
    setLoading(true)
    const updatedUser = { ...userData }
    updateUserAttributes(updatedUser, data)

    try {
      await updateUser(updatedUser)
      addNotification(NotificationType.success, NotificationMessages.SUCCESS_CHANGES_SAVED)
      setLoading(false)
    } catch (error: any) {
      console.error(error)
      addNotification(NotificationType.danger, error.message)
    }
  }

  const updateUserAttributes = (updatedUser: UserType, data: Record<string, string>) => {
    if (updatedUser.Attributes && data) {
      for (const attribute of updatedUser.Attributes) {
        if (attribute.Name && attribute.Name in data) {
          attribute.Value = data[attribute.Name]
        }
      }
    }
  }

  const getAttributeName = (attribute: AttributeType) => {
    if (attribute.Name && attribute.Name.startsWith('custom:')) {
      return attribute.Name.substring(7)
    }
    return attribute.Name
  }

  const getUserData = async () => {
    setLoading(true)
    const users = await getUserPoolUsers()
    if (users) {
      const user = users.find((user) => user.Username === userName)
      setUserData(user)
      setAttributes(user?.Attributes)
    }
    setLoading(false)
  }

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(handleFormSubmit)} style={{ maxWidth: 1080, margin: 'auto' }}>
          <Box padding={2} overflow='auto' mt={4} mb={8}>
            <Stack direction='row' mb={1} spacing={2} alignItems='center'>
              <Tooltip title='Go Back'>
                <IconButton size='small' onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
              <Typography fontWeight={400}>{userName}</Typography>
            </Stack>
            <Grid container spacing={2}>
              {attributes?.map((attribute) => {
                if (attribute.Name === 'sub' || attribute.Name === 'email_verified') {
                  return null //Wont render those attributes
                }
                const attributeName = getAttributeName(attribute)
                return (
                  <Grid item xs={12} md={6} key={attribute.Name}>
                    <Controller
                      name={attribute.Name as string}
                      control={control}
                      defaultValue={attribute.Value}
                      render={({ field }) => (
                        <TextField size='small' {...field} label={attributeName} variant='outlined' fullWidth />
                      )}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <Stack mt={4} alignItems='center'>
              <Button type='submit' variant='contained' color='secondary'>
                Save
              </Button>
            </Stack>
          </Box>
        </form>
      )}
    </>
  )
}

export default AdminEditUserDetails
