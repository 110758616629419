import { Button, Stack } from '@mui/material'
import { IStepperButtons } from '../../../interfaces'
import { useFormContext } from 'react-hook-form'

const StepperButtons = ({ activeStep, setActiveStep, totalSteps, handleContinue, file, goToTop }: IStepperButtons) => {
  const nextStep = activeStep + 1
  const isLastStep = nextStep === totalSteps ? true : false
  const { watch } = useFormContext()
  const acceptConditions = watch('acceptConditions')

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  return (
    <Stack flexDirection='row' justifyContent='center' alignItems='center' gap={2}>
      <Button
        variant='outlined'
        sx={{ minWidth: 120 }}
        onClick={() => {
          handleBack()
          goToTop()
        }}
      >
        Back
      </Button>
      <Button
        variant='contained'
        sx={{ minWidth: 120 }}
        type={isLastStep ? 'submit' : 'button'}
        onClick={() => {
          handleContinue()
          goToTop()
        }}
        disabled={(!file || acceptConditions !== 'true') && isLastStep ? true : false}
      >
        {isLastStep ? 'Submit' : 'Continue'}
      </Button>
    </Stack>
  )
}
export default StepperButtons
