import { Stack, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export default function BottomFooter() {
  return (
    <Stack className='bottomfooter-content' direction='row' alignItems='center' p='15px 0'>
      <Grid container>
        <Grid item xs={12} md={6} textAlign={{ xs: 'center', md: 'left' }} mb={{ xs: '5px', md: '0' }}>
          <Link to='/legal'>Legal</Link>
          <span className='separator'>|</span>
          <Link to='/legal/privacy'>Privacy</Link>
          <span className='separator'>|</span>
          <Link to='/legal/cookies'>Cookies</Link>
          <span className='separator'>|</span>
          <Link to='/legal/terms'>Terms of Use</Link>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack alignItems={{ xs: 'center', md: 'flex-end' }}>
            <Typography className='copyright'>
              <Link to='/'>Stuller Europe © {new Date().getFullYear()}</Link>. All rights reserved.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}
