import React from 'react'
import { ClickAwayListener, IconButton, Typography, Popover, Avatar } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'
import './navBar.css'
import { Link } from 'react-router-dom'
import useMediaQuery from '../../customHooks/screenSize'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux/es/exports'
import { logOut } from '../../redux/slices/userAccountSlice'
import { Auth } from 'aws-amplify'
import { IUserState } from '../../interfaces'

export default function NavBarProfile() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const isMobile = useMediaQuery()
  const userState = useSelector((state: { userAccount: IUserState }) => state.userAccount)
  const dispatch = useDispatch()

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = async () => {
    setAnchorEl(null)
    await Auth.signOut()
    dispatch(logOut())
  }

  const open = Boolean(anchorEl)
  return (
    <>
      {userState.isAuthenticated == true ? (
        <IconButton className='iconbtn-navbar' disableTouchRipple onClick={handleToggle}>
          <Avatar sx={{ bgcolor: '#bd9c53', width: 24, height: 24, fontSize: 14, textTransform: 'uppercase' }}>
            {userState.userName.charAt(0)}
          </Avatar>
        </IconButton>
      ) : (
        <IconButton className='iconbtn-navbar' disableTouchRipple component={Link} to='/login'>
          <AccountCircle style={{ color: isMobile ? '#222' : '#fff' }} />
        </IconButton>
      )}
      <Popover
        PaperProps={{ style: { padding: '.5rem 0' } }}
        open={open}
        elevation={5}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Link to='/' className='link-hamburger' onClick={handleLogOut}>
              <Typography className='menu-hamburger'>Log Out</Typography>
            </Link>
          </div>
        </ClickAwayListener>
      </Popover>
    </>
  )
}
