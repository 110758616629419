import { Fragment, useState } from 'react'
import { UserType } from '@aws-sdk/client-cognito-identity-provider'
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material'
import { Cancel, CheckCircle, Edit, InfoOutlined, AttachFile, Delete } from '@mui/icons-material'
import { deleteUser, disableUser, enableUser, getDocumentFromBucket, sendEmail } from '../../services'
import { useNavigate } from 'react-router'
import DialogComponent from '../Common/Dialog/DialogComponent'
import { IAdminTable } from '../../interfaces'
import { DialogActions, DialogMessages } from '../Common/Dialog/constants'
import SearchBar from '../Common/SearchBar'
import { addNotification } from '../../customHooks/notifications'
import { NotificationMessages, NotificationType } from '../../constants/notifications'
import { CURRENT_STAGE, EmailTemplates } from '../../constants'

const AdminTable = (props: IAdminTable) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserType | undefined>(undefined)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [users, setUsers] = useState(props.users)
  const [dialogMessage, setDialogMessage] = useState('')
  const [dialogFunction, setDialogFunction] = useState<DialogActions | undefined>()
  const navigate = useNavigate()

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleActivateUser = async (user: UserType) => {
    const email = user.Attributes?.find((attr) => attr.Name === 'email')?.Value
    const firstName = user.Attributes?.find((attr) => attr.Name === 'custom:firstName')?.Value

    if (email && firstName) {
      try {
        await enableUser(user.Username)
        await sendEmail(email, `${EmailTemplates.ACTIVATION}-${CURRENT_STAGE}`, firstName)
      } catch (error: any) {
        addNotification(NotificationType.danger, error.message ?? error)
        await disableUser(user.Username)
        throw new Error(error.message)
      }
      updateUserState(user, true)
    } else {
      addNotification('danger', NotificationMessages.ERROR_EMAIL_ACTIVATION)
    }
  }

  const handleDisableUser = (user: UserType) => {
    setSelectedUser(user)
    setIsDialogOpen(true)
    setDialogFunction(DialogActions.DISABLE_USER)
    setDialogMessage(DialogMessages.DISABLE_USER)
  }

  const handleDeleteUser = (user: UserType) => {
    setSelectedUser(user)
    setIsDialogOpen(true)
    setDialogFunction(DialogActions.DELETE_USER)
    setDialogMessage(DialogMessages.DELETE_USER)
  }

  const deleteSelectedUser = async (user: UserType) => {
    if (user.Username) {
      try {
        await deleteUser(user.Username)
        updateUserState(user)
      } catch (error: any) {
        addNotification(NotificationType.danger, error.message ?? error)
        setIsDialogOpen(false)
        setSelectedUser(undefined)
        throw new Error(error.message)
      }
    }

    setIsDialogOpen(false)
    setSelectedUser(undefined)
  }

  const disableSelectedUser = async (user: UserType) => {
    if (user && user.Enabled) {
      try {
        await disableUser(user.Username)
      } catch (error: any) {
        addNotification(NotificationType.danger, error.message ?? error)
        setIsDialogOpen(false)
        setSelectedUser(undefined)
        throw new Error(error.message)
      }
    }

    setIsDialogOpen(false)
    setSelectedUser(undefined)
    updateUserState(user, false)
  }

  const handleClick = (user: UserType) => {
    getDocumentFromBucket(user).then((file) => {
      if (!file.length) {
        return addNotification(NotificationType.danger, NotificationMessages.ERROR_DOCUMENT_NO_EXIST)
      }
      window.open(file)
    })
  }

  const updateUserState = (userModified: UserType, enable?: boolean) => {
    if (props.users) {
      const updatedUsers = [...props.users]
      const index = updatedUsers.findIndex((user) => user.Username === userModified.Username)

      switch (dialogFunction) {
        case DialogActions.DELETE_USER:
          if (index !== -1) {
            updatedUsers.splice(index, 1)
            props.setUsers(updatedUsers)
          }

          break

        default:
          if (index !== -1 && enable !== undefined) {
            updatedUsers[index].Enabled = enable
            props.setUsers(updatedUsers)
          }

          break
      }
    }
    addNotification(NotificationType.success, NotificationMessages.SUCCESS_CHANGES_SAVED)
  }

  const dialogAction = (user: UserType) => {
    if (dialogFunction) {
      switch (dialogFunction) {
        case DialogActions.DISABLE_USER:
          if (user.Username) {
            disableSelectedUser(user)
          }

          break
        case DialogActions.DELETE_USER:
          if (user.Username) {
            deleteSelectedUser(user)
          }

          break
        default:
          break
      }
    }
    setDialogFunction(undefined)
    setIsDialogOpen(false)
  }

  return (
    <>
      <Box maxWidth={1080} margin={'32px auto 64px'} padding={1} overflow='auto'>
        <SearchBar setUsers={setUsers} users={props.users} />
        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align='center'>Info</TableCell>
                <TableCell align='center'>Edit</TableCell>
                <TableCell align='center'>State</TableCell>
                <TableCell align='center'>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                <Fragment key={user.Username}>
                  {
                    <TableRow
                      key={user.Username}
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#f8f8f8'
                        }
                      }}
                    >
                      <TableCell>{user.Username}</TableCell>
                      <TableCell>{user.Attributes?.find((attr) => attr.Name === 'email')?.Value}</TableCell>
                      <TableCell align='center'>
                        <Stack direction='row' justifyContent='center'>
                          <Tooltip title='More Info'>
                            <IconButton size='small' onClick={() => navigate(`/admin/details/${user.Username}`)}>
                              <InfoOutlined fontSize='small' />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Attached Document'>
                            <IconButton size='small' onClick={() => handleClick(user)}>
                              <AttachFile fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title='Edit'>
                          <IconButton size='small' onClick={() => navigate(`/admin/edit/${user.Username}`)}>
                            <Edit fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip
                          title={user.Enabled ? 'Active User: click to deactivate' : 'Inactive User: click to activate'}
                        >
                          <IconButton
                            size='small'
                            onClick={() => (user.Enabled ? handleDisableUser(user) : handleActivateUser(user))}
                          >
                            {user.Enabled ? (
                              <CheckCircle style={{ color: '#5ccc2d' }} />
                            ) : (
                              <Cancel style={{ color: '#f91111' }} />
                            )}
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={'Delete User: click to delete'}>
                          <IconButton size='small' onClick={() => handleDeleteUser(user)}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  }
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component='div'
          count={props.users?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => handleChangePage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <DialogComponent
        message={dialogMessage}
        isOpen={isDialogOpen}
        action={dialogAction}
        selectedUser={selectedUser}
      />
    </>
  )
}

export default AdminTable
