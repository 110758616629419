import { Stack } from '@mui/material'
import ShowCasePrice from '../../components/ShowCasePrices'

export default function Metals() {
  return (
    <>
      <Stack>
        <div style={{ width: '100%', height: '600px', minHeight: '100vh' }}>
          <ShowCasePrice title='Metals' path={'browse/metals'} />
        </div>
      </Stack>
    </>
  )
}
