import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Button, Typography, Grid } from '@mui/material'
import useMediaQuery from '../../customHooks/screenSize'
import { Link } from 'react-router-dom'
import slidersData from './homeSlidersData.json'

interface ISliderData {
  logo?: string
  title?: string
  description: string
  bgImage: string
  className: string
  link: string
  cta: string
}

export default function HomeSliders() {
  return (
    <Carousel indicators={false}>
      {slidersData.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  )
}

function Item(props: { item: ISliderData }) {
  const isMobile = useMediaQuery()
  return (
    <Grid
      container
      className={props.item.className}
      style={{
        padding: isMobile ? '0rem' : '8vw',
        minHeight: 480,
        backgroundImage: props.item.bgImage,
        backgroundPosition: !isMobile ? 'center top' : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      {isMobile && (
        <div
          style={{
            backgroundImage: props.item.bgImage,
            width: '100%',
            height: 'auto',
            minHeight: 120,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        />
      )}
      <Grid item xs={isMobile ? 12 : 5} className={props.item.className} style={{ padding: isMobile ? '2rem' : '' }}>
        {props.item.logo && <img src={props.item.logo} style={{ maxWidth: '100%', marginBottom: '1rem' }} />}
        {props.item.title && (
          <Typography variant='h2' gutterBottom>
            {props.item.title}
          </Typography>
        )}
        <Typography fontWeight={400} marginBottom={2}>
          {props.item.description}
        </Typography>
        <Button
          variant='outlined'
          component={Link}
          to={`slider?url=browse/${props.item.link}&title=${encodeURI(props.item.title ?? '')}`}
        >
          {props.item.cta}
        </Button>
      </Grid>
    </Grid>
  )
}
