import { Box, Typography, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

const AreYouRegistered = () => {
  return (
    <Box pt={3} pb={6}>
      <Stack direction='row' justifyContent='center'>
        <Typography fontSize={14}>
          Don't have an account?
          <Link to='/registration' style={{ marginLeft: 8 }}>
            Register Now
          </Link>
        </Typography>
      </Stack>
    </Box>
  )
}

export default AreYouRegistered
