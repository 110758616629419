import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IUserState } from '../../interfaces'
import { REDUX_BASE_URL } from '../../constants'

const initialState = {
  userName: '',
  isAuthenticated: false,
  isAdmin: false,
  baseURL: REDUX_BASE_URL
}

export const userAccountSlice = createSlice({
  name: 'userAccount',
  initialState,
  reducers: {
    loginAccount: (state, action: PayloadAction<IUserState>) => {
      state.userName = action.payload.userName
      state.isAuthenticated = action.payload.isAuthenticated
      state.isAdmin = action.payload.isAdmin
      state.baseURL = action.payload.baseURL
    },
    logOut: () => {
      return initialState
    }
  }
})
export const { loginAccount, logOut } = userAccountSlice.actions
export default userAccountSlice.reducer
