import React from 'react'
import { Typography, Container, Stack, Box } from '@mui/material'
import { getData } from 'country-list'
import SelectComponent from './Inputs/SelectComponent'
import RadioButtonsGroup from './Inputs/RadioButtonsGroup'
import SelectDinamicInput from './Inputs/SelectDinamicInput'
import TextFieldComponent from './Inputs/TextFieldComponent'
import SelectWithTextFieldAndCheckboxes from './Inputs/SelectWithTextFieldAndCheckboxes'
import {
  BUSINESS_DESCRIPTION,
  JOB_ROLE,
  PRIMARY_BUSINESS,
  MARKETING_SOURCE,
  CUSTOMER_TARGET,
  PURCHASE_OPTIONS
} from '../../constants/registrationForm/index'

/**
 * @param control react-hook-form control package
 * @param errors react-hook-form errors
 * @returns Render a form to get Customer Information
 */
const CustomerInfo = () => {
  return (
    <Container>
      <Stack margin={'3rem auto 1rem'} maxWidth={600} gap={2}>
        <SelectComponent
          data={getData()}
          required={true}
          title='Where is your business located?*'
          name={'country'}
          label={'Select Country...'}
        />
        <Box>
          <Typography>Does your business operate primarily within the jewelry industry?</Typography>
          <RadioButtonsGroup name='primaryBusiness' required={true} radioValues={PRIMARY_BUSINESS} grid />
        </Box>
        <Box>
          <SelectDinamicInput
            data={MARKETING_SOURCE}
            label={'Select...'}
            primaryRequired={true}
            secondaryRequired={true}
            title={'How did you hear about us?*'}
            subInputType='radio'
            nameSubComponent='socialMedia'
            name={'media'}
            grid
          />
        </Box>
        <Box>
          <SelectDinamicInput
            data={BUSINESS_DESCRIPTION}
            primaryRequired={true}
            title='What best describes your business?*'
            name={'businessDescription'}
            label={'Select...'}
            secondaryRequired={true}
            subInputType='text'
            nameSubComponent={'businessDescriptionOther'}
          />
        </Box>
        <SelectComponent
          data={JOB_ROLE}
          required={true}
          title='What best describes your primary job role in the business?*'
          name={'jobRole'}
          label={'Select...'}
        />

        <SelectComponent
          data={CUSTOMER_TARGET}
          required={true}
          title='What type of customer do you primarily sell to?*'
          name={'typeOfCustomer'}
          label={'Select...'}
        />
        <Box>
          <Typography pb={1}>Primary Business Website</Typography>
          <TextFieldComponent
            fullWidth
            placeholder='www.example.com'
            label='Your business website'
            name={'primaryBusinessWeb'}
          />
        </Box>
        <Box>
          <SelectWithTextFieldAndCheckboxes
            data={PURCHASE_OPTIONS}
            title='Where do customers primarily purchase from you?'
            name={'primaryDistributor'}
            checkboxName={'extraDistributors'}
            label={'Select...'}
          />
        </Box>
      </Stack>
    </Container>
  )
}
export default CustomerInfo
