import React from 'react'
import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { ICheckboxComponent } from '../../../interfaces'

const CheckboxComponent = (props: ICheckboxComponent) => {
  const {
    getValues,
    control,
    formState: { errors }
  } = useFormContext()

  const handleOnChange = (onChange: (...event: any[]) => void, value: string) => {
    const currentFormValue = getValues(props.name) || []
    let updatedFormValue
    if (currentFormValue.includes(value)) {
      updatedFormValue = currentFormValue.filter((val: string) => val !== value && val)
    } else {
      updatedFormValue = currentFormValue.concat(value)
    }
    onChange(updatedFormValue)
  }

  return (
    <Controller
      rules={{ required: props.required }}
      control={control}
      name={props.name}
      render={({ field: { onChange } }) => (
        <FormGroup>
          <FormControlLabel
            key={`${props.data}-${props.keyRef}`}
            onChange={() => handleOnChange(onChange, props.data)}
            control={<Checkbox />}
            label={props.data}
          />
          <FormHelperText error>{errors[props.name]?.type && 'Required'}</FormHelperText>
        </FormGroup>
      )}
    />
  )
}
export default CheckboxComponent
