import { useCallback, useMemo } from 'react'
import { Typography } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { IDropZoneFile } from '../../../../interfaces'
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from './dropzoneStyle'
import { addNotification } from '../../../../customHooks/notifications'
import { NotificationMessages, NotificationType } from '../../../../constants/notifications'

const DropZoneComponent = ({ file, setFile }: IDropZoneFile) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Verifies if the file extension is between the accepted file types
    const fileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/gif', 'image/bmp']
    const isFileTypeValid = acceptedFiles.length > 0 && acceptedFiles.every((file) => fileTypes.includes(file.type))

    if (!isFileTypeValid) {
      return addNotification(NotificationType.danger, NotificationMessages.ERROR_FILE_TYPE)
    }
    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
      'image/bmp': [],
      'application/pdf': []
    }
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      backgroundColor: file ? '#c8e6c9' : '#fafafa',
      color: file ? '#006400' : '#bdbdbd'
    }),
    [isFocused, isDragAccept, isDragReject, file]
  )

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Typography>Select File</Typography>
      </div>
      <h6>Note:'Only PDF, PNG, JPG, JPEG, GIF and BMP are allowed</h6>
    </>
  )
}
export default DropZoneComponent
