import { Stack } from '@mui/material'
import ShowCasePrice from '../../components/ShowCasePrices'

export default function Diamonds() {
  return (
    <Stack>
      <div style={{ width: '100%', height: '600px', minHeight: '100vh' }}>
        <ShowCasePrice title='Diamonds' path={'browse/diamonds'} />
      </div>
    </Stack>
  )
}
