import * as React from 'react'
import { Tabs, Tab, Typography, Box, Stack } from '@mui/material'
import NavBarMenuItem, { INavBarMenuItem } from './navBarMenuItem'
import './navBarMegaMenu.css'
import IData from '../../menuData.json'
import CatalogueSubMenus from './catalogueSubMenus.json'
import { INavBarMegaMenu, ITabPanelProps } from './interfaces'
import { baseURLCatalogue, baseURLSubmenu, linkTarget } from '../../utils'

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 2, py: 1 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

export default function NavBarMegaMenu(props: INavBarMegaMenu) {
  const [value, setValue] = React.useState(0)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', width: '100%', padding: '1rem 2rem' }}>
      <Tabs
        indicatorColor='secondary'
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        orientation='vertical'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        sx={{ borderRight: 1, borderColor: '#555', paddingRight: 4, minWidth: 'fit-content' }}
      >
        {IData.Catalogue.map((data: INavBarMenuItem, key: number) => (
          <Tab
            onClick={(event) => {
              props?.handleClose!(event)
            }}
            LinkComponent={'a'}
            target={linkTarget()}
            href={`${baseURLCatalogue()}/${data.url}`}
            key={key}
            onMouseEnter={() => setValue(key)}
            disableRipple
            label={data.title}
            className='megamenu-tab'
            {...a11yProps(key)}
          />
        ))}
      </Tabs>
      {Object.entries(CatalogueSubMenus).map(([key, submenus], index) => {
        return (
          <TabPanel value={value} index={index} key={key}>
            <Stack direction='row' spacing={4}>
              {submenus.menus.map((submenu, index) => (
                <Box key={index}>
                  <Typography className='megamenu-title' gutterBottom>
                    {submenu.title}
                  </Typography>
                  {submenu.subproducts.map((subproduct, index) => (
                    <NavBarMenuItem
                      key={index}
                      title={subproduct.title}
                      url={baseURLSubmenu(subproduct.url, subproduct.title, 'catalogue')}
                    />
                  ))}
                </Box>
              ))}
            </Stack>
          </TabPanel>
        )
      })}
    </Box>
  )
}
