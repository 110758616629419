import React from 'react'
import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { ICheckboxFieldProps } from '../../../interfaces'
import { Link } from 'react-router-dom'

const CheckboxField = (props: ICheckboxFieldProps) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()
  return (
    <Controller
      rules={{ required: props.required }}
      control={control}
      name={props.name}
      render={({ field: { onChange, value } }) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={value === 'true'}
                onChange={(e) => onChange(e.target.checked ? 'true' : 'false')}
              />
            }
            label={
              props.link ? (
                <>
                  {props.label}
                  <Link target='_blank' to='/legal'>
                    Click here to Learn More
                  </Link>
                </>
              ) : (
                props.label
              )
            }
          />
          <FormHelperText error>{errors[props.name]?.type && 'Required'}</FormHelperText>
        </FormGroup>
      )}
    />
  )
}

export default CheckboxField
